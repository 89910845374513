<template>
<div class="vChat__main flex1 flexbox flex__direction-column" style="background:#fff">
    <div class="table-box">
        <div class="list-title">
            <div>
                <h3>群成员</h3>
                <el-button type="primary" @click="detailAction">群详情</el-button>
            </div>
		</div>
        <el-table :data="tableData" style="width: 100%"  border>
            <el-table-column prop="MemberHeadImgUrl" label="头像" width="70px">
                <template #default="scope">
                   <div class="action-wrap">
                        <img :src="scope.row.MemberHeadImgUrl" alt="">
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="NickName" label="用户昵称"></el-table-column>
            <el-table-column prop="CreateTime" label="入群时间"></el-table-column>
            <el-table-column label="操作" width="80">
                <template #default="scope">
                    <el-button type="primary" @click="outAction(scope.row)" v-if="scope.row.CommonMember==1">踢人</el-button>
                </template>
            </el-table-column>
      </el-table>
      <TableFoot
        :pagination="pageObj"
        @changePage="changePage"
        @changeSize="changeSize"
      />
    </div>
    </div>
    <OutMemberDialog ref="outMemberRef"/>
</template>

<script>
import { unref, reactive, ref, onMounted, nextTick,onUpdated } from "vue";
import { GroupMemberChatMemberList } from "@/helper/chat";
import { useRoute, useRouter } from "vue-router";
import websocket from '@/assets/js/websocket'
import { ElMessageBox, ElMessage } from "element-plus";
import { useStore } from "vuex";
import TableFoot from "@/components/TableFoot";
import OutMemberDialog from "@/components/ChatManager/OutMemberDialog"
import tools from '@/assets/js/tools'

export default {
    name:"chatGroupMember",
    components:{
        TableFoot,OutMemberDialog
    },
    setup(props,context){
        const router = useRouter();
        const outMemberRef=ref(null)
        const searchOption=reactive({
            key:"",
            ChatObj:{}
        })
        const tableData=ref([])

        onMounted(()=>{
          searchAction()
        })
        
        function getObj(obj,options) {
            console.log(obj,options)
        }
        function searchObj(obj) {
            searchOption.key=obj.groupName
            searchAction()
        }

        function searchAction(){
           let param={
                Name:searchOption.key,
                ChatRoomSerialNo:""
           }
           console.log(param);
           GroupMemberChatMemberList(param).then(res=>{
               console.log(res);
               tableData.value=res.List
               pageObj.TotalRow=res.TotalCount
           })
        }
    
        function detailAction(){
            router.push({name: "GroupInfo",query:{SID}})
        }

        function outAction(item) {
            outMemberRef.value.initCop(item)
        }

        /****************TableFoot*******************/
        const pageObj = reactive({
            TotalRow: 0,
            PageIndex: 1,
            PageSize:20,
        })
        //页码改变
        function changePage(index) {
            pageObj.PageIndex = index;
            searchAction();
        };
        function changeSize(size) {
            pageObj.PageSize = size;
            pageObj.PageIndex = 1;
            searchAction();
        }
        return{
            tableData,
            pageObj,
            searchAction,
            changePage,
            changeSize,
            outMemberRef,
            detailAction,
            outAction,
            searchOption,
            getObj,
            searchObj
        }
    }
}
</script>
<style lang='scss' scoped>
.table-box{
    height: calc(100vh - 110px);
    overflow: auto;
}
.list-title{
    div{
        display: flex;
        align-items: center;
    }
    h3{
        font-weight: 900;
        margin-right: 5px;
    }
}
.action-wrap{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    i{
        margin-left: 2px;
    }

    img{
        width: 40px;
        height: 40px;
    }

}
</style>