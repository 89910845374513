<template>
    <div class="vChat-middlebar flexbox flex__direction-column">
        <div class="vc-searArea">
            <div class="iptbox flexbox">
                <el-input placeholder="搜索" prefix-icon="el-icon-search" v-model="data.inputValue" @change="searchAction" @keydown="textareaKeydow($event)"></el-input>
                <el-dropdown style="display:flex;align-items: center;" class="global" size="large">
                     <img class="global_img" src="@/assets/imgs/chat_dropdown.png" alt="">
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item @click="globalSearch">全局搜索</el-dropdown-item>
                            <el-dropdown-item @click="globalRead">全局已读</el-dropdown-item>
                            <el-dropdown-item @click="starAction">星标管理</el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
                <!-- <el-button style="margin-left:5px;" type="primary" @click="globalSearch">全局搜索</el-button> -->
            </div>
        </div>
        
        <div class="vc-recordList flex1 flexbox flex__direction-column" style="overflow:auto;">
                <ul class="clearfix J__recordList">
                    <li class="flexbox flex-alignc wc__material-cell pointer" @click="selectClick(item)" v-for="(item,index) in data.list" :key="index" :class="(item.RobotSerialNo||'')==data.robotNo?'selected':''">
                        <div class="info flex1">
                            <div class="online"  :class="item.IsOnline==10?'':'offline'" v-if="!item.IsCollect"></div>
                            <el-icon class="star" :size="14" color="#F59A23" v-else><StarFilled /></el-icon>
                            <div v-if="!item.IsCollect">
                                <div class="robot-name">{{item.NickName}}</div>
                                <div class="wxa-name">({{item.WxAlias}})</div>
                            </div>
                            <div v-if="item.IsCollect">
                                <div class="robot-name">显示星标用户/群</div>
                            </div>
                            <div class="badge-wrap" v-if="item.IsOnline==10">
                                <div class="badge-cell">
                                    <img class="badge-img" src="@/assets/imgs/chat_icon_single.png" alt="">
                                    <em class="wc__badge" v-if="item.IsOnline==10&&(item.PNotifyCount&&item.PNotifyCount>0)">{{item.PNotifyCount}}</em>
                                    <em class="my_badge"  v-if="item.IsOnline==10&&(item.PNotifyCount&&item.PNotifyCount==0)"></em>
                                </div>
                                <div class="badge-cell">
                                    <img class="badge-img" src="@/assets/imgs/chat_icon_group.png" alt="">
                                    <em class="wc__badge" v-if="item.GNotifyCount&&item.GNotifyCount>0">{{item.GNotifyCount}}</em>
                                    <em class="my_badge" v-if="item.GNotifyCount==0"></em>
                                    <!-- <div>{{item.GNotifyCount}}</div> -->
                                </div>
                            </div>
                            
                        </div>
                    </li>
                </ul>
        </div>
         <ChatSelectCop ref='chatSelectRef' @onConfirm="onConfirmAction"/>
         <StarEditCop ref="starEditRef" />
    </div>
</template>

<script>
import { unref, reactive, ref, onMounted } from "vue";
import ChatSelectCop from "@/components/ChatManager/ChatSelectCop"
import {IgnoreAllMsg} from "@/helper/chat"
import { ElMessageBox, ElMessage } from "element-plus";
import { StarFilled } from "@element-plus/icons";
import StarEditCop from "@/components/ChatManager/StarEditCop";
export default {
  name: "robotCop",
  components: {
    ChatSelectCop,StarEditCop,StarFilled
},
  setup(props,context) {
    const data = reactive({
        list:[],
        defalutList:[],
        inputValue:'',
        robotNo:""
    })
    const chatSelectRef =ref(null)
    const starEditRef =ref(null)

    //重置数据
    function resetData(list){
        data.list = list||[]
        data.defalutList = list||[]
        data.robotNo = list[0].RobotSerialNo||''
    }

    /****************socket事件*********************/
    function socketUpData(socketList){
        
        data.defalutList = socketList||[]
        searchAction()
    }
    /****************点击事件*********************/
    //全局搜索
    function globalSearch(){
        chatSelectRef.value.initCop()
    }
    //全局已读
    function globalRead(){
        IgnoreAllMsg({}).then(res=>{
            ElMessage.success('全局已读')
        })
    }

    //星标管理
    function starAction(){
        starEditRef.value.initCop()
    }

    //定向搜索
    function onConfirmAction(robotNo,chatObj){
        data.robotNo = robotNo
        data.inputValue = ""
        searchAction()
        for(let item of data.list){
            if(item.RobotSerialNo == robotNo){
                context.emit('onChoose',item,chatObj)
                break
            }
        }

    }

    function searchAction(){
        if(data.inputValue.length>0){
            let arr = []
            for(let item of data.defalutList ){
                if(item.NickName.toUpperCase().indexOf(data.inputValue.toUpperCase())>-1){
                    arr.push(item)
                }   
            }
            data.list = arr
        } else {
            data.list = data.defalutList
        }
    }

    function textareaKeydow(event){
        if(event.keyCode == 13){
            searchAction()
        }
    }

    function selectClick(item){
        data.robotNo = item.RobotSerialNo||''
        context.emit('onChoose',item)
    }
    return {
        data,
        resetData,
        selectClick,
        searchAction,
        socketUpData,
        textareaKeydow,
        globalSearch,
        chatSelectRef,
        onConfirmAction,
        globalRead,
        starAction,
        starEditRef
    };
  }
};
</script>

<style scoped>
.info{
    display: flex;
    align-items: center;
    border-bottom: 1px solid #dedede;
    height: 50px;
    padding: 0 10px;
    box-sizing: border-box;
}
.online{
    width: 8px;
    height: 8px;
    border-radius:8px;
    background-color:#95F204;
    margin-right: 5px;
}

.offline{
    background-color: #ccc;
}

.robot-name{
    font-size: 14px;
    margin-right: 5px;
    line-height: 14px;
    margin-top: 5px;
}
.wxa-name{
    font-size: 12px;
    margin-right: 5px;
    color: #aaa;
    /* margin-top: 2px; */
}
.selected{
    background-color: #efefef;
}
.pointer{
    cursor: pointer;
}
.my_badge{
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: #ff3b30;
}
.badge-wrap{
    height: 100%;
    position: absolute;
    right: 10px;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 40px;
}
.badge-cell{
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;

}

.badge-img{
    width:12px;
    height: 12px;
    margin-right: 5px;
}
.global_img{
        width: 18px;
        height: 18px;
        margin-left:5px;
        cursor: pointer;
    }
.star{
    margin-left: -2px;
    margin-top: 2px;
}
</style>
