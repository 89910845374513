import $axios from '../assets/js/request.js'
let baseURL = process.env.VUE_APP_URL

//聊天初始化
export function ChatInit(data) {
    return $axios.post(`${baseURL}/Chat/Init`,data);
}

//聊天翻页
export function ChatPaged(data) {
    return $axios.post(`${baseURL}/Chat/Paged`,data);
}


//客户聊天列表
export function SearchContact(data) {
    return $axios.post(`${baseURL}/Chat/SearchContact`,data);
}

//回复
export function SendMsg(data) {
    return $axios.post(`${baseURL}/Chat/SendMsg`,data);
}

//全部忽略
export function IgnoreMsg(data) {
    return $axios.post(`${baseURL}/Chat/IgnoreMsg`,data);
}

//历史消息
export function GetHistory(data) {
    return $axios.post(`${baseURL}/Chat/GetHistory`,data);
}

//本地直接发送消息
export function SendMsgLocal(data) {
    return $axios.post(`${baseURL}/Chat/SendMsgLocal?MsgType=${data.MsgType}&ReplyMsgID=${data.ReplyMsgID}&Type=${data.Type}&RobotSerialNo=${data.RobotSerialNo}&FromSerialno=${data.FromSerialno}`,data.formData);
}

//刷新图片
export function RefreshMaterialInfo(data) {
    return $axios.post(`${baseURL}/Chat/MaterialInfo`,data);
}
//获得群成员列表(集聊)
export function GroupMemberChatMemberList(data) {
    return $axios.post(`${baseURL}/GroupMember/ChatMemberList`,data);
}
//全局搜索(客户群)
export function GetGlobalGroupList(data) {
    return $axios.post(`${baseURL}/Chat/GetGlobalGroupList`,data);
}
//全局搜索(客户)
export function GetGlobalCustomerList(data) {
    return $axios.post(`${baseURL}/Chat/GetGlobalCustomerList`,data);
}
//助手列表
export function GetRobotGroupList(data) {
    return $axios.post(`${baseURL}/Chat/GetRobotGroupList`,data);
}
//发送入群邀请
export function InviterJoinGroup(data) {
    return $axios.post(`${baseURL}/Chat/InviterJoinGroup`,data);
}
//忽略助手所有消息
export function IgnoreMsgByRobot(data) {
    return $axios.post(`${baseURL}/Chat/IgnoreMsgByRobot`,data);
}
//已读全部
export function IgnoreAllMsg(data) {
    return $axios.post(`${baseURL}/Chat/IgnoreAllMsg`,data);
}
//设置星标
export function CollectSet(data) {
    return $axios.post(`${baseURL}/Collect/Set`,data);
}
//获取星标配置
export function CollectGet(data) {
    return $axios.post(`${baseURL}/Collect/Get`,data);
}
