<template>
    <div class="myaudio" @click="openRecording">
        <div class="voice">
             <el-icon color="#BFB18A" :size="16" v-if="msg=='点击播放'">
                <VideoPlay />
            </el-icon>
            <el-icon color="#BFB18A" :size="16" v-if="msg=='点击暂停'">
                <VideoPause />
             </el-icon>
            <div>{{duration}}</div>
        </div>
       

        
    </div>
</template>

<script>
import { onMounted, reactive, ref, watchEffect } from "vue";
import BenzAMRRecorder from "benz-amr-recorder"
import { ElMessage, ElMessageBox } from "element-plus";
import {VideoPlay,VideoPause} from "@element-plus/icons";

export default {
    props:{
        audioUrl:{
            type:String,
            required:true
        }
    },
    components: {
        VideoPlay,
        VideoPause
    },
    name:"ChatAudioCop",
    setup(props,context){
        const amr= ref(null)
        const msg= ref("点击播放")
        const duration = ref('0.0')
        onMounted(()=>{
            initVoice()
        })

        function initVoice(){
            amr.value = new BenzAMRRecorder()
            let url= `${props.audioUrl}?t=${new Date().getTime()}`
            amr.value.initWithUrl(url).then(()=>{
                duration.value = amr.value.getDuration()
            }).catch((err=>{
                console.log("无法播放",err)
            }))
            
            amr.value.onEnded(()=>{
                msg.value="点击播放"
            })
        }

        function openRecording(){
            if(amr.value != null){
                stopPlayVioce()
            }
        }

        function stopPlayVioce(){
            if(amr.value.isPlaying()){
                amr.value.stop();
                msg.value = "点击播放"
            } else {
                amr.value.play();
                msg.value = "点击暂停";
            }
        }

        return {
            amr,
            msg,
            openRecording,
            stopPlayVioce,
            duration
        }
    }
}
</script>

<style lang="scss" scoped>
.myaudio{
    // height: 26px;
    width: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    // padding: 5px;
    box-sizing: border-box;
    // background-color: #f2f2f2;
    border-radius: 5px;
    
    .voice{
        font-size: 12px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.myaudio:hover{
     cursor: pointer;
}
</style>
