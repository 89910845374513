import store from '@/store/index'
import crypto from '@/assets/js/crypto'
import { ElMessage, ElMessageBox } from "element-plus";
import { HubConnectionBuilder, HubConnectionState,HttpTransportType} from "@microsoft/signalr";
var builder = null;
var isConnectSelf = false; //是否手动重连
var websocket = {
  Created(){
    if(!builder){
      if(store.getters.userInfo&&store.getters.userInfo.Token.length){
        this.Init()
      } else {

      }
    } 
  },
  StartConnect(){
    // 开始
    if (this.connection.state !== HubConnectionState.Connected) {
      this.connection.start().then((res) => {
        if(isConnectSelf){
          isConnectSelf =false
          this.sendInvoke(store.getters.chatInfo)
        }
        console.log("启动即时通信成功");
      }).catch(err=>{
        this.ShowDisconnect()
      });
    }
  },
  ShowDisconnect(){
    ElMessageBox.confirm(`socket断连,请确认完网络状态,再执行手动恢复`, "确认提示", {
      confirmButtonText: "手动恢复",
      cancelButtonText: "取消",
      type: "warning"
    }).then(() => {
        isConnectSelf = true
        this.StartConnect()
      }).catch(() => {
        ElMessage({
          type: "info",
          message: "已取消"
        });
      });
  },
  Init: function() {
    builder = new HubConnectionBuilder();
    let urlPath =this.getProUrl()
    this.connection =builder.withUrl(`${document.location.protocol}${urlPath}/chat/msg`,{
      accessTokenFactory: () => store.getters.userInfo.Token,
      skipNegotiation: true,
      transport: HttpTransportType.WebSockets
    }).withAutomaticReconnect({ nextRetryDelayIsnMilliseconds: () => 3000 }) //断线重连
    .build();
    //自动重连成功后的处理
    this.connection.onreconnected((connectionId) => {
      console.log(connectionId, "自动重新连接成功");
    });

    // 监听关闭
    this.connection.onclose((res) => {
      this.ShowDisconnect()()
    });
    this.StartConnect()
  },
  //获取正式url
  getProUrl(){
    if(document.location.host.indexOf('siyugj.com')>-1){
      if(document.location.host.indexOf('esteehelperuat.siyugj.com')>-1){
        return "//esteehelperuat.siyugj.com"
      } else {
        return '//signalr.siyugj.com'
      }
    } else {
      return "//sqgjtest.yilouapp.com"
    }
  },
  //获取正式url
  getProUrl(){
    if(document.location.host.indexOf('siyugj.com')>-1){
      if(document.location.host.indexOf('esteehelperuat.siyugj.com')>-1){
        return "//esteehelperuat.siyugj.com"
      }else if(document.location.host.indexOf('aldihelper.siyugj.com')>-1){
        return "//signalraldi.siyugj.com"
      } else {
        return '//signalr.siyugj.com'
      }
    } else {
      return "//sqgjtest.yilouapp.com"
    }
  },

  //发送信息
  sendInvoke(data){
    if(this.connection){
      this.connection.invoke("InitChat", data.RobotSerialNo, data.FromSerialNo, data.PageSize,data.FirstMsgId);
    }
  },

  //发送离线信息
  sendCancelLoops(data){
    if(this.connection){
      this.connection.invoke("CancelLoops");
    }
  },

  //监听信息
  addListioner(callback){
    if(this.connection){
      this.connection.on("ReceiveChatMsg", (res) => {
        let obj =  JSON.parse(res)
        if(obj.IsSuccess){
          callback(obj.ResultData)
        }
      });
    }
    
  }
  
}

export default websocket;