<template>
  <div>
            <!-- 文本 -->
            <div class="msg" v-if="sourceData.data.MsgType==2001" style="white-space: pre-wrap;" v-html="filterEmoji(sourceData.data.MsgContent)"></div>
            <!-- 图片 -->
            <div class="msg picture picExtend" v-else-if="sourceData.data.MsgType==2002">
                <div class="mousewrap" v-if="sourceData.data.showHover&&sourceData.data.IsSelf==1&&sourceData.data.MsgContent.length<=0">
                    <div class="recall-my" style="margin-right:5px;"  @click='refreshPic(sourceData.data)'>刷新</div>
                </div>
                <el-image class="img__pic" style="width:200px;height:200px;" :src="sourceData.data.MsgContent" preview="1" :preview-src-list="[sourceData.data.MsgContent]"></el-image>
            </div>
            <!-- 图文混合 -->
            <!-- <div class="msg"  v-else-if="sourceData.data.MsgType=='mix'">
                {{sourceData.data.message.text}}
                <img class="face" :src="sourceData.data.message.image" />
            </div> -->
            <!-- 链接 -->
            <div class="msg" v-else-if="sourceData.data.MsgType==2005">
                {{sourceData.data.MsgTitle}}<br> 
                <a :href="sourceData.data.MsgDesc">{{sourceData.data.MsgUrl}}</a>
            </div>
            <!-- 视频 -->
            <div class="msg video" style="padding: 0px;" v-else-if="sourceData.data.MsgType==2004">
                <video class="img__video" controls>
                    <source :src="sourceData.data.MsgUrl" />
                </video>
                <!-- <img class="img__video" :src="sourceData.data.MsgContent" :videoUrl="sourceData.data.MsgUrl"/> -->
            </div>
            <!-- 文件 -->
            <!-- <div class="msg attachment" v-else-if="sourceData.data.MsgType=='file'">
                <div class="card flexbox flex-alignc"> 
                    <i class="ico-bg"></i>
                    <div class="file-info flex1" :title="sourceData.data.message.fileName">
                        <p class="name">{{sourceData.data.message.fileName}}{{sourceData.data.message.filePre}}</p>
                        <p class="size">{{sourceData.data.message.size}}</p>
                    </div> 
                    <a class="btn-down" href="#" target="_blank" :download="sourceData.data.message.href"></a>
                </div>
            </div> -->
            <!-- 表情 -->
            <div class="msg picture"  v-else-if="sourceData.data.MsgType==2007">
                <img  class="img__pic"  :src="sourceData.data.MsgContent" alt="" preview="1" >
            </div>
            <!-- 语音 -->
            <div class="msg"  v-else-if="sourceData.data.MsgType==2003">
                <ChatAudioCop :audioUrl="sourceData.data.MsgContent"/>
            </div>
            <!-- 名片 -->
            <div class="msg attachment"  v-else-if="sourceData.data.MsgType==2006">
                <div class="msg-body">
                    <div class="msg-head">
                        <div class="left-wrap">
                            <div>{{sourceData.data.message.MsgContent}}</div>
                            <div>{{sourceData.data.message.userid}}</div>
                        </div>
                    </div>
                    <div class="msg-foot">
                        <div>个人名片</div>
                    </div>
                </div>
            </div>
            <!-- 视频号 -->
            <div class="msg"  v-else-if="sourceData.data.MsgType==2017">
                <div class="cell">
                    <div class="video-header">
                        <img class="face" src="@/assets/imgs/video.png">
                        <div class="cell-desc">{{sourceData.data.MsgDesc}}</div>
                    </div>
                    <!-- <img class="videoimg" src="@/assets/imgs/video.png">
                        <div class="cell-desc">{{sourceData.data.MsgDesc}}</div> -->
                    <img class="msgHref" :src="sourceData.data.MsgUrl">
                </div>
                <!-- <img class="face" src="@/assets/imgs/mini-video.png" alt=""> -->
                <!-- 视频号:{{sourceData.data.MsgTitle}}<br> -->
                <!-- <span>&emsp;&nbsp;&nbsp;描述:&nbsp;{{sourceData.data.MsgDesc}}</span> -->
            </div>
            <!-- 小程序 -->
            <div class="msg" v-else-if="sourceData.data.MsgType==2013">
                <img class="face" src="@/assets/imgs/mini-logo.png" alt="">
                小程序:{{sourceData.data.MsgTitle}}<br>
                <span>&emsp;&nbsp;&nbsp;标题:{{sourceData.data.MsgContent}}</span><br>
                <span>&emsp;&nbsp;&nbsp;路径:{{sourceData.data.MsgDesc}}</span>
            </div>
            <!-- 小程序 -->
            <div class="msg" v-else-if="sourceData.data.MsgType==123000">
                <div class="invite">
                     <img class="face" src="@/assets/imgs/invite.png" alt="">
                    <div>邀请加入群聊:{{sourceData.data.MsgTitle}} </div>
                </div>
               
            </div>
            <div class="msg" v-else>
                
                <div class="no-support">不支持的消息类型</div>
            </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref, watchEffect } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import Tool from "@/assets/js/tools";
import ChatAudioCop from "./chatAudioCop";
import {emotions} from '@/assets/js/emoji';
export default {
  name: "chatMsgType",
  components: { ChatAudioCop},
    props:{
      obj:{
          type:Object,
          default:{}
      }  
    },
  setup(props, context) {
        const sourceData = reactive({
          data:props.obj
        })
        onMounted(()=>{
          
      })
        /***********************点击事件************************/
        //转化表情
        function filterEmoji(val){
            let emojiList=emotions()
            for(let item of emojiList){
                var regexp = new RegExp('\\[' + item.text + '\\]|\\/' + item.text, 'gm');
                val = val.replace(regexp, '<img class="emoji" src="' + item.img + '"/>');
            }
            return val
        }
        
        //点击刷新
        function refreshPic(item){
            context.emit("refreshPic",item)
        }
        return {
            filterEmoji,
            sourceData,
            refreshPic
        };
    },
};
</script>
<style lang='scss' scoped>

.msg-wrap{
    color: $color-text;
    .cell-row{
        display: flex;
        flex-direction: row;
        align-items: center;
        
        .cell-center{
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 5px 15px;
            box-sizing: border-box;
            border: 1px dashed #ccc;
            font-size: 12px;
            background-color: #f2f2f2;
        }

        img{
            width: 15px;
            height: 15px;
        }

        .check-btn{
            margin-left: 20px;
        }
    }

    
}
.invite{
    display: flex;
    flex-direction: row;

    img{
        margin: 0px 5px;
    }
}

.recall-my{
    width: 50px;
    height: 22px;
    font-size: 11px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    color: #BFB18A;
    border: 1px solid #ccc;
    border-radius: 22px;
    background-color: #fff;
    cursor: pointer;
}

.mousewrap{
    display: flex;
    align-items: center;
    margin-top: 5px;

}
.picExtend{
    display: flex;
    flex-direction: row;
}

//名片
.msg-body{
        width: 200px;
        box-sizing: border-box;

        .msg-foot{
            font-size: 12px;
            color: #999;
            margin-top:5px;

        }
 
        .msg-head{
            height: 100px;
            width: 100%;
            display: flex;
            flex-direction: row;
            border-bottom: 1px solid $color-border;

            .left-wrap{
                width: calc(100% - 50px);
                height: 100%;
                
            }
            .left-wrap div:nth-child(1){
                font-size: 15px;
                font-weight: 600;
            }
            .left-wrap div:nth-child(3){
                font-size: 16px;
                margin-top: 20px;
                font-weight: 800;
            }
            .left-wrap div:nth-child(2){
                font-size: 12px;
                margin-top: 20px;
                color: #666;
            }

            img{
                width: 40px;
                height: 40px;
                margin-left: 10px;
            }
        }
}
.cell {
        position: relative;
        margin: 5px 0px;
        width:160px;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
       .msgHref{
            width: 100%;
            height: 100px;
            padding-top: 5px;
        }
      .cell-desc{
            width: 100%;
            font-size: 14px;
            padding: 0 5px;
            box-sizing: border-box;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
        }
    }
.video-header{
    width: 100%;
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    // align-items: center;
    font-size: 14px;
    box-sizing: border-box;
    vertical-align:top;
    .face{
        height: 25px!important;
    }
     .videoimg{
            width: 40px;
            padding: 5px;
            line-height: 20px;
            display: block;
        }
}
</style>