<template>
  <div class="container-out">
    <el-dialog title="操作提示" width='600px' v-model="dialogVisible">
        <div class="box">
            <div>将群成员【<span class="common-color">{{peopleObj.FromNickName}}</span>】从所有群聊中踢出</div>
            <!-- <div class="radio">
                <el-radio-group el-radio-group v-model="outRadio">
                    <el-radio :label="0">踢出所有群聊并加入群聊黑名单</el-radio>
                    <el-radio :label="1">踢出所有群聊</el-radio>
                </el-radio-group>
            </div> -->
        </div>
        <template #footer>
              <div class="dialog-footer">
                 <el-button type="primary" @click="confirmAction">确 定</el-button>
                 <el-button @click="dialogVisible = false">取 消</el-button>
              </div>
          </template>
    </el-dialog>
  </div>
</template>


<script>
import { onMounted, reactive, ref, unref } from "vue";
import { RemoveAllGroup} from "@/helper/group";

export default {
  name: "OutMemberDialog",
  setup(props, context) {
    const dialogVisible = ref(false);
    const peopleObj = reactive({
      RobotSerialNo:'',
      SerialNo:'',
      FromNickName:""
    })
    const outRadio=ref(0)
    function initCop(robotno,serialno,name) {
      dialogVisible.value = true;
      peopleObj.RobotSerialNo = robotno
      peopleObj.SerialNo = serialno
      peopleObj.FromNickName = name
    }

    /*****************点击事件*********************/
   function confirmAction(){
      let data = {
        RobotSerialNo:peopleObj.RobotSerialNo,
        MemberSeiralNo:peopleObj.SerialNo,
        SetBlackList:0
      } 
      RemoveAllGroup(data).then(res=>{
        dialogVisible.value = false
        context.emit("onComfirm")
      })
       
   }

    return {
      initCop,
      dialogVisible,
      confirmAction,
      outRadio,
      peopleObj
    };
  }
};
</script>
<style lang='scss' scoped>
.box{
    margin: 20px 20px 0;
    font-size: 16px;
    .radio{
        margin-top: 10px;
    }
}
:deep().el-radio-group{
    display: flex;
    flex-direction: column;
}
:deep().el-radio{
    padding: 10px;
}
.common-color{
  color: $color-common;
}
</style>