<template>
  <div>
    <el-dialog title="添加@的成员" width='600px' v-model="dialogVisible">
        <el-form :model="ruleForm" :rules="rules" ref="ruleFormRef" class="ruleForm" @submit.native.prevent>
            <el-form-item label="成员昵称/群昵称" prop="name">
                <div class="member-bg">
                    <el-input v-model="ruleForm.name"  placeholder="请填写成员昵称/群昵称" @change="changeAction"></el-input>
                    <el-button style="margin-left:10px;" type="primary" @click="searchAction" >搜索</el-button>
                    <el-button @click="resetAction" >重置</el-button>
                </div>
                
            </el-form-item>
            
            <el-form-item label="搜索结果:" v-if="list&&list.length>0">
                <div v-for="item in list" :key="item" class="member-bg space" @click="selecAction(item)">
                    <div>
                        <img :src="item.MemberHeadImgUrl" alt="">
                        <div style="margin-left:5px;">{{item.NickName}}</div>
                    </div>
                    <el-icon :size="20" color='#BFB18A' v-if="isSelectComputed(item.MemberSerialNo)"><CircleCheckFilled  /></el-icon>
                </div>  
            </el-form-item>
            
        </el-form>
      <template #footer >
        <div class="dialog-footer">
          <el-button @click="cancelAction" >取 消</el-button>
          <el-button type="primary" @click="confirmAction" >确 定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive,ref,onMounted,computed} from 'vue'
import { ElMessage } from "element-plus";
import { GetGroupMemberSimpleList } from "@/helper/group.js";
import { CircleCheckFilled} from "@element-plus/icons";
import _ from "lodash";
export default {
    name:"MemberSelectCop",
    components:{
        CircleCheckFilled
    },
    setup(props,context) {
        const dialogVisible = ref(false);
        let selectObj = reactive({
            data:{}
        })
        const list = ref([])
        const ruleFormRef = ref(null);
        const ruleForm = reactive({
            name: "",
        });
        const rules = reactive({
            name: [
                { required: true, message: "请填写成员名称", trigger: "blur" },
            ],
        });
        const serialNo =  ref('')
        function initCop(SerialNo){
            dialogVisible.value = true;
            selectObj.data ={}
            serialNo.value = SerialNo||''
            ruleForm.name=''
            list.value = []
        }
        
        //是否选择
        const isSelectComputed=computed(()=>{
            return (SerialNo)=>{
                let obj = selectObj.data[SerialNo]
                return obj?true:false
            }
        })
        /***************点击事件******************/
        function searchAction(){
            if(ruleForm.name.length>0){
                GetGroupMemberSimpleList({ Name: ruleForm.name,ChatRoomSerialNo:serialNo.value}).then((res) => {
                    list.value = res.List;
                });
            } else {
                list.value =[]
            }
            
        }
        function resetAction(){
            ruleForm.name = ''
            list.value = []
            searchAction()
        }
        //点击
        function selecAction(item){
            //多选使用
            // let obj = selectObj.data[item.SerialNo]
            // if(obj){
            //     delete selectObj.data[item.SerialNo]
            // } else{
            //     selectObj.data[item.SerialNo] = item
            // }
            selectObj.data ={}
            selectObj.data[item.MemberSerialNo] = item
        }

        //确定点击
        const confirmAction=_.debounce(()=>{
            dialogVisible.value = false;
            let valueList = Object.values(selectObj.data)
            let obj ={}
            if(valueList&&valueList.length>0){
                obj = valueList[0]
            }
            context.emit('onConfirm',obj);
        },1000,{
            leading: true, //在延迟开始前立即调用事件
            trailing: false, //在延时结束后不调用,保证事件只被触发一次
        });

        //取消
        function cancelAction(){
            dialogVisible.value = false;
            context.emit('concel');
        }

        function changeAction(){
            return
        }

        return {
            initCop,
            dialogVisible,
            confirmAction,
            rules,
            ruleForm,
            ruleFormRef,
            searchAction,
            resetAction,
            list,
            isSelectComputed,
            selecAction,
            serialNo,
            cancelAction,
            changeAction

        }
    }
}
</script>
<style lang='scss' scoped>
.ruleForm {
  //   width: 700px;
  margin: 30px 40px;
}
.member-bg{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    overflow: auto;
    max-height: 400px;

    div{
        display: flex;
        flex-direction: row;
        align-items: center;
        img{
            width: 30px;
            height: 30px;
        }
    }
    
}

.space{
    box-sizing:border-box;
    padding: 5px 10px;
    cursor: pointer;
    border: 1px solid #f3f3f3;
}

</style>