<template>
  <div class="container">
    <el-dialog title="星标管理" width='650px' v-model="dialogVisible">

        <el-form :model="ruleForm" :rules="rules" ref="ruleFormRef" label-width="120px" class="ruleForm">
            <el-form-item label="关联企业标签">
                <div>
                    <label>已选择({{ruleForm.data.enterpriseTags.length||0}})个</label>
                    <el-button type="primary" style="margin-left:5px;" @click="enterpriseAction">选择</el-button>
                </div>
            </el-form-item>
            <el-form-item label="关联群标签">
                <div>
                    <label>已选择({{ruleForm.data.groupTags.length||0}})个</label>
                    <el-button type="primary" style="margin-left:5px;" @click="groupAction">选择</el-button>
                </div>
            </el-form-item>
        </el-form>

        <template #footer>
              <div class="dialog-footer">
                 <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
                 <el-button @click="dialogVisible = false">取 消</el-button>
              </div>
          </template>
    </el-dialog>
    <SingleTagCop ref="tagSelectRef" :showRadio="false" @onConfirm="tagConfirmAction" />
    <GroupFieldCop ref="groupFieldRef" @onConfirm="sureSelectAction"/>
  </div>
</template>


<script>
import { onMounted, reactive, ref, unref } from "vue";
import {CollectSet,CollectGet} from '@/helper/chat'
import { ElMessageBox,ElMessage } from 'element-plus';
import SingleTagCop from "@/components/SingleTagCop";
import GroupFieldCop from '@/components/GroupFieldCop';

import _ from "lodash";
export default {
  name: "StarEditCop",
    components: {
    SingleTagCop,GroupFieldCop
  },
  setup(props, context) {
    const dialogVisible = ref(false);
    const tagSelectRef = ref(null);
    const groupFieldRef = ref(null)
    const ruleFormRef = ref(null);
    const ruleForm = reactive({
        data:{
            groupTags:[],
            enterpriseTags:[]
        }
     
    });
    const rules = reactive({
        data:{
            Color: [
        { required: true, message: "请填写群名称", trigger: "change" },
      ],
      CollectName: [
        { required: true, message: "请填写星标定义", trigger: "change" },
      ],
        }
      
    });
    onMounted(()=>{})
    
    function initCop() {
      dialogVisible.value = true;
      CollectGet({}).then(res=>{
        ruleForm.data.groupTags = res.GroupTags||[]
        ruleForm.data.enterpriseTags = res.WeComTags||[]
      })
    }

   
    /*****************点击事件*********************/
    //群标签
    function groupAction(){
        groupFieldRef.value.initCop(2,ruleForm.data.groupTags)
    }

    //确认群标签
    function sureSelectAction(_,arr){
        ruleForm.data.groupTags = arr
    }

    //企业标签
    function enterpriseAction(){
        tagSelectRef.value.initCop(0, ruleForm.data.enterpriseTags)
    }

    //标签确认
    function tagConfirmAction(type, arr) {
      ruleForm.data.enterpriseTags = arr;
    }

     //确定表单
    const submitForm = _.debounce(
      async () => {
        const form = unref(ruleFormRef);
        if (!form) return;
        try {
          await form.validate();
          
          let param = {
            GroupTags:ruleForm.data.groupTags&&ruleForm.data.groupTags.length>0?ruleForm.data.groupTags.map(item=>item.ID):'',
            WeComTags:ruleForm.data.enterpriseTags&&ruleForm.data.enterpriseTags.length>0?ruleForm.data.enterpriseTags.map(item=>item.ID):'',
          }
          CollectSet(param).then(res=>{
            ElMessage({
              type: "success",
              message: "提交成功",
            });
            dialogVisible.value = false;
            context.emit("onConfirm");
          })
          
        } catch (error) {
          console.log(error);
        }
      },
      1000,
      {
        leading: true, //在延迟开始前立即调用事件
        trailing: false, //在延时结束后不调用,保证事件只被触发一次
      }
    );
    return {
      initCop,
      dialogVisible,
      ruleFormRef,
      ruleForm,
      rules,
      submitForm,
      tagSelectRef,
      groupFieldRef,
      enterpriseAction,
      tagConfirmAction,
      sureSelectAction,
      groupAction
    };
  }
};
</script>
<style lang='scss' scoped>
.ruleForm {
  // width: 400px;
  margin: 20px 20px 0;
  p {
    font-size: 12px;
    color: #bbb;
  }
  .item-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    .item-group-count {
      margin: 0 10px;
    }
    span {
      color: $color-common;
    }
  }
}
</style>