<template>
  <div>
    <el-dialog title="全局搜索" width='600px' v-model="dialogVisible">
        <el-form :model="ruleForm" :rules="rules" ref="ruleFormRef" class="ruleForm" label-width="100px">
            <el-form-item label="搜索类型" prop="type">
                <div class="cell-row">
                <el-select  v-model="ruleForm.type" placeholder="全部" @change="changeTypeAction">
                    <el-option v-for="item in typeList" :key="item.Value" :label="item.Label" :value="item.Value"></el-option>
                </el-select>
                </div>
            </el-form-item>
            
            <el-form-item :label="ruleForm.type==0?'客户群名称':'客户名称'" prop="name">
                <div class="cell-row">
                    <el-popover :visible="isVisible" placement="bottom" width="300px">
                        <div class="cell-wrap">
                            <div class="cell space" v-for="item in searchList" :key="item" @click="selectAction(item)">
                                {{item.FromNickName}}
                            </div>
                        </div>
                        
                        <template #reference>
                            <el-input v-model="ruleForm.name" :placeholder="`请输入${ruleForm.type==0?'客户群名称':'客户名称'}，名称越全搜索越准`"></el-input>
                        </template>
                    </el-popover>
                    
                    <el-button style="margin-left:10px;" type="primary" @click="searchAction" >搜索</el-button>
                    <el-button @click="resetAction" >重置</el-button>    
                </div> 
            </el-form-item>

            <el-form-item label="管理助手" prop="robot">
                <div class="cell-row" v-if="ruleForm.type==0">
                    {{selectObj.data.RobotName}}
                </div>
                <div class="cell-row" v-if="ruleForm.type==1">
                    <el-select  v-model="selectObj.robotNo" placeholder="检测到该客户添加了多名助手">
                        <el-option v-for="item in selectObj.data.RobotList" :key="item.RobotSerialNo" :label="item.RobotName" :value="item.RobotSerialNo"></el-option>
                    </el-select>
                </div>
            </el-form-item>
            
        </el-form>
      <template #footer >
        <div class="dialog-footer">
          <el-button @click="cancelAction" >取 消</el-button>
          <el-button type="primary" @click="confirmAction" >进入会话</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive,ref,onMounted,computed} from 'vue'
import { ElMessage } from "element-plus";
import { CircleCheckFilled} from "@element-plus/icons";
import {GetGlobalGroupList,GetGlobalCustomerList}from "@/helper/chat";
import _ from "lodash";
export default {
    name:"ChatSelectCop",
    components:{
        CircleCheckFilled
    },
    setup(props,context) {
        const dialogVisible = ref(false);
        const ruleFormRef = ref(null);
        const ruleForm = reactive({
            name: "",
            type:0,
            robot:""
        });
        const rules = reactive({
            name: [
                { required: true, message: "请填写成员名称", trigger: "blur" },
            ],
            type :[
                { required: true, message: "请选择类型", trigger: "change" },
            ],
            robot:[
                { required: true, message: "请选择助手", trigger: "blur" },
            ],
        });
        const typeList = ref([{Label:"搜索客户群",Value:0},{Label:"搜索客户",Value:1}])

        function initCop(){
            dialogVisible.value = true;
            resetAction()
        }
        /***************点击事件******************/
        const searchList = ref([])
        const isVisible = ref(false)
        function searchAction(){
            ruleForm.type==0?remoteGroup():remoteCustomer()
        }
        //搜索客户群
        function remoteGroup(){
            GetGlobalGroupList({Name:ruleForm.name}).then(res=>{
                 searchList.value = res.List
                 isVisible.value = true
            })
        }
        //搜索客户
        function remoteCustomer(){
            GetGlobalCustomerList({Name:ruleForm.name}).then(res=>{
                searchList.value = res.List
                isVisible.value = true
                
            })
        }
        function resetAction(){
            ruleForm.name = ''
            ruleForm.type = 0
            searchList.value = []
            selectObj.data = {}
            selectObj.robotNo = ''
            isVisible.value = false
        }
        
        //单选
        let selectObj=reactive({
            data:{},
            robotNo:""
        })
        function selectAction(item){
            isVisible.value = false
            ruleForm.name = item.FromNickName
            selectObj.data = item;
            selectObj.robotNo =  ruleForm.type==0?item.RobotSerialNo:''
   
        }

        //更改类型
        function changeTypeAction(){
            selectObj.data = {}
            selectObj.robotNo = ''
            isVisible.value = false
        }
        
        //确定点击
        const confirmAction=_.debounce(()=>{
            dialogVisible.value = false;
            isVisible.value = false

            if(selectObj.data.FromNickName.length<=0){
                ElMessage.error(`请选择${ruleForm.type==0?'客户群':'客户'}`)
                return
            }
            if(selectObj.robotNo.length<=0){
                ElMessage.error("请选择助手")
                return
            }

            context.emit('onConfirm',selectObj.robotNo,selectObj.data);
        },1000,{
            leading: true, //在延迟开始前立即调用事件
            trailing: false, //在延时结束后不调用,保证事件只被触发一次
        });

        //取消
        function cancelAction(){
            dialogVisible.value = false;
            isVisible.value = false
            context.emit('concel');
        }
        return {
            initCop,
            dialogVisible,
            confirmAction,
            rules,
            ruleForm,
            ruleFormRef,
            searchAction,
            resetAction,
            typeList,
            cancelAction,
            remoteGroup,
            remoteCustomer,
            searchList,
            isVisible,
            selectAction,
            selectObj,
            changeTypeAction

        }
    }
}
</script>
<style lang='scss' scoped>
.ruleForm {
  margin: 30px 40px;
  p {
    font-size: 12px;
    color: #999;
  }
  .item-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    .item-group-count {
      margin: 0 10px;
    }
    span {
      color: $color-common;
    }
  }
}

.cell-row{
    width: 100%;
    display: flex;
    
}
.cell-wrap{
    width: 100%;
    max-height: 300px;
    overflow: auto;

    .cell{
        width: 100%;
        height: 30px;
        cursor: pointer;
        margin-bottom:5px;
    }
}

.space{
    box-sizing:border-box;
    padding: 5px 10px;
    cursor: pointer;
    border: 1px solid #f3f3f3;
}
:deep().el-input--mini{
    width: 300px !important;   
}
</style>