<template>
  <div>
    <el-dialog title="添加小程序" width='600px' v-model="dialogVisible">
        <!-- header -->
        <div class="header-wrap">
            <el-input placeholder="请输入小程序名称/编号" prefix-icon="el-icon-search" v-model="searchObj.key" style="width:200px;" @change="searchMinis"></el-input>
        </div>

        <div class="body-wrap">
            <div class="cell-wrap">
                <div :class="{'selectborder':selectItem.ID==item.ID,'noborder':selectItem.ID!=item.ID}" class="cell"  v-for="(item,index) in minis" :key="index" @click="selectAction(item)">
                    <div class="cell-top">
                        <img class="mini_btn" src="../../assets/imgs/chat_btn_mini.png" alt="">
                        <p>{{item.MsgTitle}}</p>
                        
                    </div>
                    <div class="msg_content">{{item.MsgContent}}</div>
                    <p class="cell-desc">{{item.MsgDesc}}</p>
                    <img class="href" :src="item.MsgHref">
                    <p class="cell-desc" style="color:#BFB18A">【{{item.ID}}】{{item.Remark}}</p>
                    <!-- <div class="cell-foot">
                        <img src="../../assets/imgs/mini-logo.png" alt="">
                        <p>小程序</p>
                    </div> -->
                    <div class="cell-tip" v-if="selectItem.ID==item.ID">
                        <el-icon :size="25" :color="'#BFB18A'">
                            <CircleCheckFilled />
                        </el-icon>
                    </div>
                </div>
            </div>
        </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="cancelAction" >取 消</el-button>
          <el-button type="primary" @click="confirmAction" >确 定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive,ref,onMounted} from 'vue'
import { CircleCheckFilled } from '@element-plus/icons';
import {GetMaterialList} from '@/helper/material'
import { ElMessage } from "element-plus";
import _ from "lodash";
export default {
    components:{CircleCheckFilled},
  setup(props,context) {
    const dialogVisible = ref(false);
    const selectItem = ref({})
    const searchObj = reactive({
        key:'',
    })
    const pageObj = reactive({
        pageIndex:1,
        pageSize:20,
        totalCount:0,
    })

    function initCop(){
        dialogVisible.value = true;
        minis.value = []
        searchObj.key = ''
        searchMinis();
    }

    const minis = ref([])
    function searchMinis(){
        let param={
            Type:2013,
            PageNum:pageObj.pageIndex,
            PageSize:pageObj.pageSize,
            Content:searchObj.key
        }
        GetMaterialList(param).then(res=>{
            pageObj.totalCount = res.TotalCount;
            minis.value = res.List
            res.List.forEach(item=>{
                item.isSelect = false;
            })

        })
    }

    /***************点击事件******************/
    //确定点击
    const confirmAction=_.debounce(()=>{
        if(selectItem.value.ID){
            dialogVisible.value = false;
            context.emit('onConfirm',selectItem.value);
        } else{
            ElMessage({
                type:"info",
                message:"请至少选一个小程序"
            })
        }
        
    },1000,{
      leading: true, //在延迟开始前立即调用事件
      trailing: false, //在延时结束后不调用,保证事件只被触发一次
    });

    //取消
    function cancelAction(){
        dialogVisible.value = false;
        context.emit('concel');
    }
    //选择
    function selectAction(item){
        selectItem.value = item
    }

    return {
        initCop,
        dialogVisible,
        confirmAction,
        searchObj,
        selectItem,
        minis,
        selectAction,
        pageObj,
        searchMinis,
        cancelAction
    }
  }
}
</script>
<style lang='scss' scoped>
.header-wrap{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    div{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}

.body-wrap{
    width: 100%;
    
    .cell-wrap{
        width: 100%;
        max-height: 500px;
        margin: 10px auto;
        overflow: auto;
        display: grid;
        grid-template-columns: repeat(auto-fill, 160px);
        justify-content: space-between;
        .selectborder {
            border: 1px solid #C1AE83;
        }
        .noborder{
            border:1px solid #ccc;
        }

        .cell{
            position: relative;
            // border: 1px solid #ccc;
            margin: 5px 0px;
            width:160px;
            height: 190px;
            display: flex;
            flex-direction: column;
            align-items: center;
            box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);
            box-sizing: border-box;

           
            .href{
                width: 120px;
                height: 100px;
                padding:10px;
                border-bottom: 1px solid #ccc;
            }

            .cell-tip{
                position: absolute;
                top: 0;
                right: 0;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #fff;
            }
            .cell-desc{
                width: 100%;
                font-size: 12px;
                color: #ccc;
                text-align: center;
                padding: 0 5px;
                box-sizing: border-box;
                overflow:hidden;
                text-overflow:ellipsis;
                white-space: nowrap;

            }
            .msg_content{
                width: 100%;
                font-size: 12px;
                padding:0 6px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                box-sizing: border-box;
               
            }
            .cell-foot{
                width: 100%;
                font-size: 8px;
                display: flex;
                flex-direction: row;
                img{
                    width: 10px;
                    height: 10px;
                    border: none;
                }
            }

            .cell-top{
                width: 100%;
                margin-top: 5px;
                display: flex;
                flex-direction: row;
                align-items: center;
                font-size: 14px;
                color: #000;
                box-sizing: border-box;
                
             .mini_btn{
                width: 25px;
                height: 25px;
                padding: 5px;
            }

                
                p{
                    overflow:hidden;
                    text-overflow:ellipsis;
                    white-space: nowrap;
                    color: $color-common;
                }
                
            }
        }
            
    }
}
:deep().el-pager li.active{
    color: $color-common !important;
}
</style>