<template>
  <div>
    <el-dialog title="请选择" width='600px' v-model="dialogVisible">
        
        <div class="table-box">
            <div class="tips">未满40人的群可以直接将客户拉进群，无需客户同意，超过40人的客户群将发送邀请链接且需要用户同意才能进</div>
            <el-table :data="tableData" style="width: 100%" border>
                <el-table-column prop="RobotName" label="助手名称" ></el-table-column>
                <el-table-column label="请选择群聊">
                    <template #default="scope">
                        <el-select v-model="data.groupId" placeholder="全部" filterable>
                            <el-option v-for="item in scope.row.GroupList" :key="item.GroupID" :label="item.GroupName" :value="item.GroupID"></el-option>
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column label="群内人数">
                    <template #default="scope">
                        <div>{{userComputed}}</div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="table-box" style="margin-top:20px">
            <div class="tips">该用户当前所在群聊</div>
            <div style="margin-left:10px;" v-if="!tableDataV2||tableDataV2.length<=0">
                不在群内
            </div>
            <el-table :data="tableDataV2" style="width: 100%" border v-else-if="tableDataV2&&tableDataV2.length<=5">
                <el-table-column prop="GroupName" label="群名称" width="250"></el-table-column>
                <el-table-column prop="AdminNickName" label="群主" ></el-table-column>
                <el-table-column prop="MonitorNickName" label="管理助手" ></el-table-column>
            </el-table>
            <el-table v-else :data="tableDataV2" style="width: 100%" border height="250">
                <el-table-column prop="GroupName" label="群名称" width="250"></el-table-column>
                <el-table-column prop="AdminNickName" label="群主" ></el-table-column>
                <el-table-column prop="MonitorNickName" label="管理助手" ></el-table-column>
            </el-table>
        </div>
      <template #footer > 
        <div class="dialog-footer">
          <el-button @click="cancelAction" >取 消</el-button>
          <el-button type="primary" @click="confirmAction" >发 送</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive,ref,onMounted,computed} from 'vue'
import { ElMessage, ElMessageBox } from "element-plus";
import { GetRobotGroupList,InviterJoinGroup,IgnoreMsgByRobot} from "@/helper/chat";

import _ from "lodash";
export default {
    name:"InviteGroupCop",
    components:{},
    setup(props,context) {
        const dialogVisible = ref(false);
        const tableData = ref([])
        const data = reactive({
            superInfo:{},
            response :[],
            dist:{},
            groupId:''
        })

        const tableDataV2 = ref([])
        
        function initCop(info){
            console.log(info);
            dialogVisible.value = true;
            data.superInfo = info||{}
            data.dist = {}
            data.groupId =''
            loadGroup()
        }
        
        //人数
        const userComputed = computed(()=>{
            return data.dist[data.groupId]? data.dist[data.groupId].UserCount||0:0
        })

        function loadGroup(){
            let params={
                RobotSerialNo:data.superInfo.robotItem.RobotSerialNo?data.superInfo.robotItem.RobotSerialNo:data.superInfo.chatItem.RobotSerialNo?data.superInfo.chatItem.RobotSerialNo:'',
                FromSerialNo:data.superInfo.chatItem.FromSerialNo
            }
            GetRobotGroupList(params).then(res=>{
                tableData.value = [res]
                tableDataV2.value = res.MemberInGroupList
                for(let item of res.GroupList){
                    data.dist[item.GroupID] = item
                }
            })
        }
        /***************点击事件******************/
        //确定点击
        const confirmAction=_.debounce(()=>{
            let arr = tableDataV2.value.map(item=>item.GroupID)
            let params = {
                RobotSerialNo:data.superInfo.robotItem.RobotSerialNo?data.superInfo.robotItem.RobotSerialNo:data.superInfo.chatItem.RobotSerialNo?data.superInfo.chatItem.RobotSerialNo:'',
                GroupID:data.groupId,
                FromSerialNo:data.superInfo.chatItem.FromSerialNo||''
            }
            if(arr.indexOf(data.groupId)>-1){
                ElMessageBox.confirm(`该用户已在所选的群聊内，你确定要发送入群邀请吗？？`, '确认提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    InviterJoinGroup(params).then(res=>{
                        dialogVisible.value = false;
                        context.emit('onConfirm',[res.MessageInfo]);
                    })
                }).catch(() => {
                    ElMessage({
                        type: 'info',
                        message: '已取消',
                    });
                });
            }else{
                InviterJoinGroup(params).then(res=>{
                    dialogVisible.value = false;
                    context.emit('onConfirm',[res.MessageInfo]);
                })
            }
        },1000,{
            leading: true, //在延迟开始前立即调用事件
            trailing: false, //在延时结束后不调用,保证事件只被触发一次
        });

        //取消
        function cancelAction(){
            dialogVisible.value = false;
            context.emit('concel');
        }


        return {
            initCop,
            dialogVisible,
            confirmAction,
            cancelAction,
            tableData,
            loadGroup,
            userComputed,
            data,
            tableDataV2
        }
    }
}
</script>
<style lang='scss' scoped>
.tips{
    font-size: 12px;
    color: $color-text;
    padding: 10px;
}

</style>