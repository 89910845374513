<template>
    <div ref="scrollRef" class="vChat__main flex1 flexbox flex__direction-column" style="overflow:auto;"  v-infinite-scroll="loadNewData" infinite-scroll-disable="busy" infinite-scroll-distance="10" @scroll="scrollAction">
        <div class="vChat__loading J__vChatLoading" v-if="isEnd==false">
            <img src="@/assets/imgs/chat/deng.gif" /> 
            数据载入中...
        </div>
        <!-- <div class="vChat__loading J__vChatLoading" v-else>
            数据已到头
        </div> -->
        <div class="vChatMsg-cnt">
            <ul class="clearfix">
                <li v-for="(item,index) in data.list" :key="index" @mouseenter="item.showHover=true" @mouseleave="item.showHover=false" >
                    <div class="notice" v-if="item.MsgType==10000">
                        <span>{{item.MsgContent}}</span>
                    </div>
                    <div class="time" v-else-if="item.messageType=='cancel'">
                        <span>{{item.MsgContent}}</span>
                    </div>
                    <div :class="item.IsSelf==1?'me':'others'" v-else>
                        <el-icon :size="15" color="#F59A23" class="icon-size" v-if="item.IsSelf!=1&&item.IsCollect==1"><StarFilled /></el-icon>
                        <div class="icon-size" v-else></div>
                        <img class="avatar" src="@/assets/imgs/default_user.png" v-if="item.IsSelf!=1&&item.FromHeadImg.length<=0"/>
                        <img class="avatar" :src="item.FromHeadImg" v-if="item.IsSelf!=1&&item.FromHeadImg.length>0"/>
                        <div class="avatar-tip" v-if="item.IsSelf!=1&&item.Ack==0&&searchOption.RobotObj.IsOnline==10"></div>
                        <div class="content">
                            <p class="author">{{item.IsSelf==1?item.NickName:item.FromNickName}}</p>
                            <div style="display:flex;flex-direction: row;flex-wrap: wrap;align-items: flex-start;">
                                <ChatMsgTypeCop :obj="item" :key="item.MsgID" v-if="item.IsSelf==1" style="justify-content: flex-end;width:100%;"/>
                                <ChatMsgTypeCop :obj="item" :key="item.MsgID" v-else style="justify-content: flex-start;"/>

                                <div class="mousewrap" v-if="item.showHover&&item.IsSelf!=1&&item.MsgType==2001">
                                    <div class="recall" @click='reCall(item)'>回复</div>
                                    <div class="recall" style="margin-left:5px;" @click='responseCall(item)'  v-if="searchOption.ChatObj.Type==1">@对方</div>
                                    <div class="recall" style="margin-left:5px;"  @click='forceOut(item)'  v-if="searchOption.ChatObj.Type==1&&item.CommonMember==1">踢人</div>
                                    <el-icon :size="20" class="icon-class" @click='ignoreCall(item)' v-if="item.Ack==0"><circle-close /></el-icon>
                                    
                                </div>
                                <div class="mousewrap" v-else-if="item.showHover&&item.IsSelf!=1&&item.MsgType!=2001">
                                    <div class="recall" style="margin-left:5px;"  @click='responseCall(item)'  v-if="searchOption.ChatObj.Type==1">@对方</div>
                                    <div class="recall" style="margin-left:5px;"  @click='refreshPic(item)'  v-if="item.MsgType==2002&&item.MsgContent.length<=0">刷新</div>
                                    <div class="recall" style="margin-left:5px;"  @click='forceOut(item)'  v-if="searchOption.ChatObj.Type==1&&item.CommonMember==1">踢人</div>
                                   
                                </div>
                            </div>
                            
                             <div class="time">{{item.MsgTime}}</div>
                        </div>
                        
                        <img class="avatar" :src="item.FromHeadImg" v-if="item.IsSelf==1&&item.FromHeadImg.length>0"/>
                        <img class="avatar" :src="item.HeadImgUrl" v-if="item.IsSelf==1&&item.FromHeadImg.length<=0&&item.HeadImgUrl.length>0"/>
                        <img class="avatar" src="@/assets/imgs/default_user.png" v-if="item.IsSelf==1&&item.FromHeadImg.length<=0&&item.HeadImgUrl.length<=0"/>
                        
                    </div>
                    
                </li>
            </ul>
        </div> 
    </div>
</template>

<script>
import { unref, reactive, ref, onMounted, nextTick } from "vue";
import ChatMsgTypeCop from "./chatMsgType";
import {GetHistory,IgnoreMsg} from"@/helper/chat"
import Tool from"@/assets/js/tools"
import {CircleClose,StarFilled} from '@element-plus/icons';
import { ElMessageBox, ElMessage } from "element-plus";
export default {
  name: "chatHistory",
  components: { ChatMsgTypeCop,CircleClose,StarFilled},
  setup(props,context) {
    const data = reactive({
      list: [],
    });
    const searchOption= reactive({
        RobotObj:{},
        ChatObj:{},
        PageSize:20,
        FirstMsgId:0,
        LastMsgId:0,
        key:"",
        data:'',
        state:0,
    })

    //防止重复更新
    const canLoad = ref(true)
    const busy = ref(false)
    const isEnd = ref(false)
    const scrollRef = ref(null)
    onMounted(() => {
        isEnd.value = false
        holdHeight = 0
        canLoad.value = false
    });


    //最新消息接收
    function loadData(){
        canLoad.value = false
        //聊天初始化
        GetHistory(dealwithRequest()).then(res=>{
            data.list = dealwithData(res.List)
            nextTick(()=>{
                scrollRef.value.scrollTop = scrollRef.value.scrollHeight
                setTimeout(() => {
                    canLoad.value = true
                }, 1000);
            })
        })
    }

    //处理数据
    function dealwithData(list=[]){
        let arr = []
        for(let i= list.length-1;i>=0;i--){
             let temp = {
                HeadImgUrl:searchOption.RobotObj.HeadImgUrl||'',
                NickName : searchOption.RobotObj.NickName||searchOption.ChatObj.RobotNickName||'',
                WxAlias :  searchOption.RobotObj.WxAlias||searchOption.ChatObj.RobotWxAlias||''
            }
            arr.push(Object.assign({},searchOption.RobotObj,list[i],temp))
        }
        if(list&&list.length<20){
            isEnd.value = true
        }else {
            isEnd.value = false
        }
        return arr
    }
    //处理请求
    function dealwithRequest(){
        return {
            Type:searchOption.ChatObj.Type,
            RobotSerialNo:searchOption.RobotObj.RobotSerialNo?searchOption.RobotObj.RobotSerialNo:searchOption.ChatObj.RobotSerialNo?searchOption.ChatObj.RobotSerialNo:'',
            FromSerialNo:searchOption.ChatObj.FromSerialNo||"",
            PageSize:searchOption.PageSize,
            FirstMsgId:searchOption.FirstMsgId||0,
            LastMsgId:searchOption.LastMsgId||0,
            Start:searchOption.date&&searchOption.date.length>0?Tool.GMTToStr(searchOption.date[0]):'',
            End:searchOption.date&&searchOption.date.length>0?Tool.GMTToStr(searchOption.date[1],1,1):'',
            MsgType:'',
            Content:searchOption.key,
            SelfType:searchOption.state,
        }
    }
    
    //忽略全部消息
    function ignoreAll(maxId){
        if(data.list.length>0){
            data.list.forEach(item=>{
                if((maxId&&item.MsgID<=maxId&&item.Ack==0)||(!maxId&&item.Ack==0)){
                    item.Ack = 2
                }
                
            })
        }
    }

    //忽略单条消息
    function ignoreSingleMsg(maxId){
        if(data.list.length>0){
            data.list.forEach(item=>{
                if(maxId==item.MsgID&&item.Ack==0){
                    item.Ack = 2
                }
            })
        }
    }
    
    /****************更新机器人和聊天信息*******************/
    //更新搜索对象
    function initOptions(obj,options){
        searchOption.RobotObj = obj.robotItem
        searchOption.ChatObj = obj.chatItem

        console.log("1231231231",searchOption)
        searchOption.key = options.key
        searchOption.date = options.date
        searchOption.state = options.state
        loadData()
    }

    //更新搜索条件
    function initQuery(obj){
        searchOption.key = obj.key
        searchOption.date = obj.date
        searchOption.state = obj.state
        loadData()
    }

    /****************滚动事件*******************/
    //持有高度
    let holdHeight =  0
    function scrollAction(e){
        if (e.target.scrollTop <= 0&&!busy.value) {
          loadHistoryData();
        }
    }

    //历史消息拉取
    function loadHistoryData(){
        holdHeight = scrollRef.value.scrollHeight
        busy.value = true
        let oldId = data.list&&data.list.length>0?data.list[0].MsgID:0
        let options = dealwithRequest()
        options.LastMsgId = oldId
        //聊天初始化
        GetHistory(options).then(res=>{
            let list = dealwithData(res.List).concat(data.list)
            data.list = list
            nextTick(()=>{
                let topHeight = scrollRef.value.scrollHeight - holdHeight
                scrollRef.value.scrollTop=topHeight
            })
            busy.value = false
        })
    }

    //最新消息接收
    function loadNewData(){
        if(!canLoad.value){
            return
        }
        busy.value = true
        holdHeight = scrollRef.value.scrollHeight
        let maxId = data.list&&data.list.length>0?data.list[data.list.length-1].MsgID:0
        let options = dealwithRequest()
        options.FirstMsgId = maxId
         //聊天初始化
        GetHistory(options).then(res=>{
            let list = data.list.concat(dealwithData(res.List))
            data.list = list
            nextTick(()=>{
                scrollRef.value.scrollTop= holdHeight
            })
            busy.value = false
        })
    }

    /****************点击事件*******************/
    //忽视
    function ignoreCall(item){
         if(searchOption.RobotObj.IsOnline!=10){
            ElMessage({
                type:"info",
                message:"助手已离线,无法进行操作"
            })
            return
        }
        let options = {
            Type:searchOption.ChatObj.Type,
            RobotSerialNo:searchOption.RobotObj.RobotSerialNo?searchOption.RobotObj.RobotSerialNo:searchOption.ChatObj.RobotSerialNo?searchOption.ChatObj.RobotSerialNo:'',
            FromSerialNo:searchOption.ChatObj.FromSerialNo||"",
            MsgID:item.MsgID
        }
        IgnoreMsg(options).then(res=>{
            item.Ack = 2
            context.emit("onIgnore",item.MsgID)
        })
    }

    //回复
    function reCall(item){
         if(searchOption.RobotObj.IsOnline!=10){
            ElMessage({
                type:"info",
                message:"助手已离线,无法进行操作"
            })
            return
        }
        context.emit("recall",item)
    }

    //@对方
    function responseCall(item){
        if(searchOption.RobotObj.IsOnline!=10){
            ElMessage({
                type:"info",
                message:"助手已离线,无法进行操作"
            })
            return
        }
        context.emit("responsecall",item)
    }
    //刷新图片
    function refreshPic(item){
        if(searchOption.RobotObj.IsOnline!=10){
            ElMessage({
                type:"info",
                message:"助手已离线,无法进行操作"
            })
            return
        }
        let options={
            Type:searchOption.ChatObj.Type,
            MsgID:item.MsgID
        }
        RefreshMaterialInfo(options).then(res=>{
            item.MsgContent = res.Material.MsgContent
        })
    }
    
    //强制踢人
    function forceOut(item){
        context.emit("forceOut",item)
    }

    return {
      data,
      reCall,
      ignoreCall,
      loadHistoryData,
      loadNewData,
      scrollAction,
      busy,
      scrollRef,
      searchOption,
      ignoreAll,
      initOptions,
      initQuery,
      isEnd,
      canLoad,
      ignoreSingleMsg,
      responseCall,
      refreshPic,
      forceOut

    };
  }
};
</script>

<style scoped>
.avatar-tip{
    position:absolute;
    top: -4px;
    left: -4px;
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 100%;
}
.mousewrap{
    display: flex;
    align-items: center;
    margin-top: 5px;

}
.icon-class{
    margin-left: 10px;
    cursor: pointer;
    color:#BFB18A;
    background-color: #fff;
    border-radius: 20px;
    overflow: hidden;
   
}
.recall{
    width: 50px;
    height: 22px;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #BFB18A;
    border: 1px solid #ccc;
    border-radius: 22px;
    background-color: #fff;
    cursor: pointer;

}

.recall-my{
    width: 50px;
    height: 22px;
    font-size: 11px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    color: #BFB18A;
    border: 1px solid #ccc;
    border-radius: 22px;
    background-color: #fff;
    cursor: pointer;
}
.time{
    margin: 0 10px;
    color: #aaa;
    font-size: 12px;
}
.icon-size{
    height: 30px;
    line-height: 30px;
    width: 15px;
}
</style>
