<template>
  <div class="container">
    <el-dialog title="选择标签" width="60%" v-model="dialogVisible">
      <div class="wrap-fliter" v-if="showRadio">
        <span>筛选条件:</span>
        <el-radio-group v-model="searchObj.radio" class="radio-group" @change="changeRadio">
          <el-radio :label="0">以上标签满足其中之一</el-radio>
          <el-radio :label="1">以上标签同时满足</el-radio>
          <el-radio :label="-1">无任何标签</el-radio>
        </el-radio-group>
      </div>

      <el-input
        placeholder="请输入企业标签"
        suffix-icon="el-icon-search"
        v-model="searchObj.key"
        @change="changeInputAction"
      ></el-input>

      <div class="wrap">
        <div class="group-title" v-for="(item, index) in tagList" :key="index">
          <!-- <el-tag  effect="dark"></el-tag> -->
          <p>{{ item.GroupName }}</p>
          <div class="cell">
            <div
              v-for="(item2, index2) in item.Tag"
              :key="index2"
              @click="selectAction(index, index2)"
            >
              <el-tag style="cursor:pointer"
                effect="dark"
                type="success"
                size="small"
                v-if="item2.isSelect"
                >{{ item2.TagName }}
              </el-tag>
              <el-tag  style="cursor:pointer" effect="plain" type="success" size="small" v-else>
                {{ item2.TagName }}
              </el-tag>
            </div>

            <!-- <p :class="item2.isSelect?'selected':''" v-for="(item2,index2) in item.list" :key="index2" @click="selectAction(index,index2)">{{item2.title}}</p> -->
          </div>
        </div>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirmAction">确 定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, ref, onMounted } from "vue";
import { GetSimpleTagList } from "@/helper/robot";
export default {
  props:{
    //是否展示
    showRadio:{
      type:Boolean,
      default:true
    },
  },
  setup(props, context) {
    const dialogVisible = ref(false);
    const searchObj = reactive({
      radio: 0,
      key: "",
    });

    function initCop(type, arr) {
      dialogVisible.value = true;
      resetData();
      searchObj.radio = type || 0;
      searchAction(arr);
    }

    const tagList = ref([]);
    function searchAction(arr) {
      GetSimpleTagList().then((res) => {
        sourceList.value = res.List;
        tagList.value = res.List;
        let arrGroup = arr.map((ele) => ele.ID);
        if (arr && arr.length > 0) {
          res.List.forEach((item) => {
            item.Tag.forEach((item2) => {
              if (arrGroup.indexOf(item2.ID) > -1) {
                item2.isSelect = true;
              }
            });
          });
        }
      });
    }
    //重置
    function resetData() {
      searchObj.radio = 0;
      searchObj.key = "";
      sourceList.value = [];
      tagList.value = [];
    }
    /***********点击事件*************/
    const sourceList = ref([]); //原始数据
    //切换Radio
    function changeRadio(){
      if(searchObj.radio==-1&& tagList.value&& tagList.value.length>0){
        tagList.value.forEach(item => {
          for(let item2 of item.Tag){
            item2.isSelect = false
          }
        })
      }
    }

    //筛选
    function changeInputAction() {
      let arr = [];
      sourceList.value.forEach((item) => {
        item.Tag.forEach((item2) => {
          if (
            item2.TagName.toUpperCase().indexOf(searchObj.key.toUpperCase()) >
            -1
          ) {
            arr.push(item);
          }
        });
      });
      tagList.value = arr;
    }
    //选中
    function selectAction(index, index2) {
      if(searchObj.radio==-1){
        return
      }
      let item = tagList.value[index].Tag[index2];
      item.isSelect = item.isSelect ? false : true;
    }
    //确认
    function confirmAction() {
      let selectlist = [];
      if (searchObj.radio != 3) {
        tagList.value.forEach((item) => {
          if (item.Tag && item.Tag.length > 0) {
            item.Tag.forEach((v) => {
              if (v.isSelect) {
                selectlist.push(v);
              }
            });
          }
        });
      }

      dialogVisible.value = false;
      context.emit("onConfirm", searchObj.radio, selectlist);
    }

    return {
      dialogVisible,
      initCop,
      confirmAction,
      searchObj,
      searchAction,
      tagList,
      sourceList,
      selectAction,
      resetData,
      changeInputAction,
      changeRadio
    };
  },
};
</script>
<style lang='scss' scoped>
.wrap-fliter {
  margin: 10px 0;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  span {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-shrink: 0;
    letter-spacing: 0;
    line-height: 14px;
    font-size: 14px;
  }
  .radio-group {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 5px;
    box-sizing: border-box;

    :deep() .el-radio__label {
      padding-left: 5px;
    }
  }
}

.wrap {
  width: 100%;
  max-height: 400px;
  overflow: auto;

  display: flex;
  flex-direction: column;

  .group-title {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    font-size: 14px;
    p {
      margin: 5px 0;
      padding-bottom: 5px;
      font-size: 13px;
      color: $color-text;
      border-bottom: 1px dashed $color-text;
    }
  }
  .cell {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    div {
      margin: 5px 10px 5px 0;
      border-radius: 3px;
    }
  }
}

:deep() .el-tag--plain {
  border-color: $color-text;
  color: $color-text;
}

:deep() .el-tag--dark {
  background-color: $color-common;
  border-color: $color-common;
}
</style>