<template>
  <div>
    <el-dialog title="添加链接" width='600px' v-model="dialogVisible">
        <!-- header -->
        <div v-if="!isCreate">
            <div class="header-wrap">
                <el-input placeholder="请输入链接标题/编号" prefix-icon="el-icon-search" v-model="searchObj.key" style="width:200px;" @change="searchLinks"></el-input>
                <div>
                    <el-button type="primary" @click="isCreate=true">新建链接</el-button>
                </div>
            </div>
            <div class="body-wrap">
                <div class="cell-wrap">
                    <div :class="{selectBorder:selectItem.ID==item.ID,noSelectBorder:selectItem.ID!=item.ID}" class="cell" v-for="item in links" :key="item.ID" @click="selectAction(item)">
                        <div class="cell-left">
                            <p>【{{item.ID}}】{{item.MsgTitle}}</p>
                            <p class="cell-desc">{{item.MsgDesc}}</p>
                        </div>
                        <img :src="item.MsgContent">
                        <div class="cell-tip" v-if="selectItem.ID==item.ID">
                            <el-icon :size="25" :color="'#BFB18A'">
                                <CircleCheckFilled />
                            </el-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="cop-width" v-else>
            <CreateLinkCop @submit="submitAction" @cancel="isCreate=false"/>
        </div>
      <template #footer >
        <div class="dialog-footer" v-if="!isCreate">
          <el-button @click="cancelAction" >取 消</el-button>
          <el-button type="primary" @click="confirmAction" >确 定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive,ref,onMounted} from 'vue'
import CreateLinkCop from   "@/components/GroupMsg/CreateLinkCop"
import { CircleCheckFilled } from '@element-plus/icons';
import {GetMaterialList} from '@/helper/material'
import { ElMessage } from "element-plus";
import _ from "lodash";
export default {
    name:"LinkSendCop",
    components:{CreateLinkCop,CircleCheckFilled},
    setup(props,context) {
    const dialogVisible = ref(false);
    const isCreate = ref(false);
    const searchObj = reactive({
        key:'',
    })

    const pageObj = reactive({
        pageIndex:1,
        pageSize:20,
        totalCount:0,
    })

    function initCop(){
        dialogVisible.value = true;
        links.value = []
        searchObj.key = ''
        searchLinks();
    }

    const links = ref([])
    const selectItem = ref([])
    
    function searchLinks(){
        let param={
            Type:2005,
            PageNum:pageObj.pageIndex,
            PageSize:pageObj.pageSize,
            Content:searchObj.key
        }
        GetMaterialList(param).then(res=>{
            pageObj.totalCount = res.TotalCount;
            links.value = res.List
        })
    }

    /***************点击事件******************/
    //确定点击
    const confirmAction=_.debounce(()=>{
        if(selectItem.value.ID){
            dialogVisible.value = false;
            context.emit('onConfirm',selectItem.value);
        } else{
            ElMessage({
                type:"info",
                message:"请至少选一个链接"
            })
        }
    },1000,{
      leading: true, //在延迟开始前立即调用事件
      trailing: false, //在延时结束后不调用,保证事件只被触发一次
    });

    //取消
    function cancelAction(){
        dialogVisible.value = false;
        context.emit('concel');
    }

    //选择
    function selectAction(item){
        selectItem.value = item
    }

    //表单提交
    function  submitAction(obj){
        isCreate.value = false;
        searchLinks()
        // links.value.push(obj)
    }

    return {
        isCreate,
        initCop,
        dialogVisible,
        confirmAction,
        searchObj,
        selectItem,
        links,
        selectAction,
        submitAction,
        pageObj,
        searchLinks,
        cancelAction
    }
  }
}
</script>
<style lang='scss' scoped>
.header-wrap{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    div{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}

.cop-width{
    width: 500px;
    margin: auto;
}

.body-wrap{
    width: 100%;
    
    .cell-wrap{
        width: 100%;
        max-height: 350px;
        margin: 10px auto;
        overflow: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        box-sizing: border-box;

        .selectBorder {
            border: 1px solid #C1AE83;
        }
        .noSelectBorder{
            border: 1px solid #ccc;
        }
    
        .cell{
            position: relative;
            width:240px;
            height: 100px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            // border: 1px solid #ccc;
            margin-bottom: 5px;
            box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);

            img{
                width: 80px;
                height: 80px;
                margin:0 10px;
            }

            .cell-tip{
                position: absolute;
                top: 0;
                right: 0;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #fff;
            }

            .cell-left{
                height: 80px;
                width: 140px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                font-size: 14px;
                color: #000;
                padding-left: 5px;
                box-sizing: border-box;

                .cell-desc{
                    font-size: 12px;
                    color: #ccc
                }
                p{
                    text-overflow: -o-ellipsis-lastline;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    line-clamp: 2;
                    -webkit-box-orient: vertical;
                    line-height: 20px;
                }

                
            }
        }
            
    }
}
:deep().el-pager li.active{
    color: $color-common !important;
}
</style>