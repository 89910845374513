<template>
  <div>
    <el-dialog title="添加图片" width="600px" v-model="dialogVisible">
      <!-- header -->
      <div class="header-wrap">
        <el-input
          placeholder="请输入图片名称/编号"
          prefix-icon="el-icon-search"
          v-model="searchObj.key"
          style="width: 200px"
          @change="serachImages"
        ></el-input>
        <div>
          <p>上传图片不超过4M</p>
          <el-upload
            class="avatar-uploader"
            action="#"
            accept="image/jpeg,image/jpg,image/png,image/gif"
            :http-request="changeImage"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            :auto-false="false"
          >
            <el-button type="primary">上传至素材库</el-button>
          </el-upload>
          <el-upload
            class="avatar-uploader"
            style="margin-left:10px"
            accept="image/jpeg,image/jpg,image/png,image/gif"
            action="#"
            :http-request="changeImage2"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            :auto-false="false"
          >
            <el-button type="primary">本地直接发送</el-button>
          </el-upload>
        </div>
      </div>

      <div class="body-wrap">
        <div class="cell-wrap">
          <div
            class="cell"
            :class="{
              selectBorder:selectItem.ID==item.ID,
              noSelectBorder: selectItem.ID!=item.ID,
            }"
            v-for="item in imgs"
            :key="item.ID"
            @click="selectAction(item)"
          >
            <img :src="item.MsgContent" />
            <p>【{{item.ID}}】{{ item.Remark }}</p>
            <div class="cell-tip" v-if="selectItem.ID==item.ID">
              <el-icon :size="25" :color="'#BFB18A'">
                <CircleCheckFilled />
              </el-icon>
            </div>
          </div>
        </div>
        
      </div>
      <div class="page-foot">
          <el-pagination
            layout="prev, pager, next"
            :total="pageObj.totalCount"
            @current-change="currentchange"
          ></el-pagination>
        </div>

      <template #footer>
        <div class="dialog-footer">
          <el-button @click="cancelAction">取 消</el-button>
          <el-button type="primary" @click="confirmAction">确 定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, ref, onMounted } from "vue";
import { CircleCheckFilled } from "@element-plus/icons";
import { ElMessage } from "element-plus";
import { GetMaterialList, CreateImageOrVideo } from "@/helper/material";
import { SendMsgLocal } from "@/helper/chat";

import _ from "lodash";
export default {
  components: {
    CircleCheckFilled,
  },
  setup(props, context) {
    const dialogVisible = ref(false);
    const selectItem = ref({})

    const searchObj = reactive({
      key: "",
      //聊天对象
      data:{}
    });
    const pageObj = reactive({
      pageIndex: 1,
      pageSize: 10,
      totalCount: 0,
    });

    function initCop(obj) {
      dialogVisible.value = true;
      imgs.value = [];
      searchObj.key = ''
      searchObj.data = JSON.parse(JSON.stringify(obj))||{}
      serachImages();
    }

    //图片
    const imgs = ref([]);

    //搜索
    function serachImages() {
      let param = {
        Type: 2002,
        PageNum: pageObj.pageIndex,
        PageSize: pageObj.pageSize,
        Content:searchObj.key
      };
      GetMaterialList(param).then((res) => {
        pageObj.totalCount = res.TotalCount;
        imgs.value = res.List;
      });
    }

    /***************点击事件******************/
    //确定点击
    const confirmAction=_.debounce(()=>{
      if(selectItem.value.ID&&selectItem.value.ID>0){
        dialogVisible.value = false;
        context.emit("onConfirm", selectItem.value);
      } else {
        ElMessage({
          type:'info',
          message:"请至少选择一张图片"
        })
      }
    },1000,{
      leading: true, //在延迟开始前立即调用事件
      trailing: false, //在延时结束后不调用,保证事件只被触发一次
    });

    //取消
    function cancelAction(){
        dialogVisible.value = false;
        context.emit('concel');
    }

    let lock = false;
    //图片变更
    function changeImage(event) {
      if(lock) return
      setTimeout(() => {
        lock = false;
      }, 1000);
      
      lock = true;
      const formData = new FormData();
      formData.append("file", event.file);
      CreateImageOrVideo({ Type: 2002, formData }).then((res) => {
        serachImages()
        ElMessage.success({
          message: "上传成功",
          type: "success",
        });
      });
    }

    //本地直接发送
    function changeImage2(event){
      let options={
        MsgType:2002,
        Type:searchObj.data.chatItem.Type,
        ReplyMsgID:searchObj.data.msgID,
        FromSerialno:searchObj.data.chatItem.FromSerialNo||'',
        RobotSerialNo:searchObj.data.robotItem.RobotSerialNo||''
      }
      const formData = new FormData();
      formData.append("file", event.file);
      options.formData = formData
      SendMsgLocal(options).then(res=>{
        dialogVisible.value = false;
        context.emit("updateLocalSend",res.MessageList);
      })
    }
    //检测图片
    function beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 4;
      const isImage= file.type == 'image/png' || 'image/jpg'|| 'image/jpeg' || 'image/gif';
      if (!isLt2M) {
        ElMessage({
          type: "error",
          message: "上传图片大小不能超过 4MB!",
        });
      }
      if (!isImage) {
        ElMessage({
            type: "error",
            message: "上传图片只支持png/jpeg/jpg/gif!",
        });
      }
      return isLt2M&&isImage;
    }

    //选择
    function selectAction(item) {
        selectItem.value = item
    }

    /****************分页********************/
    //切换分页
    function currentchange(val) {
      pageObj.pageIndex = val;
      serachImages();
    }

    return {
      initCop,
      dialogVisible,
      confirmAction,
      searchObj,
      pageObj,
      imgs,
      serachImages,
      selectAction,
      currentchange,
      beforeAvatarUpload,
      changeImage,
      changeImage2,
      selectItem,
      cancelAction
    };
  },
};
</script>
<style lang='scss' scoped>
.header-wrap {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;

    p {
      font-size: 12px;
      margin-right: 10px;
    }
  }
}

.body-wrap {
  width: 100%;
  overflow: auto;

  .cell-wrap {
    width: 100%;
    max-height: 350px;
    margin: 10px auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, 100px);
    justify-content: space-between;
    .selectBorder {
      border: 1px solid #C1AE83;
    }
    .noSelectBorder {
      border: 1px solid #ccc;
    }

    .cell {
      position: relative;
      margin: 5px 0;
      width: 100px;
      height: 120px;
      // border: 1px solid #ccc;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      img {
        width: 100%;
        height: 100%;
      }

      .cell-tip {
        position: absolute;
        top: 0;
        right: 0;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
      }
      p {
        width: 100%;
        height: 20px;
        background-color: rgba($color: #000000, $alpha: 0.2);
        font-size: 12px;
        position: absolute;
        bottom: 0;
        left: 0;
        line-height: 20px;
        text-align: center;
        color: #fff;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
      }
    }
  }
  
}
.page-foot {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
:deep().el-pager li.active {
  color: $color-common !important;
}
</style>