<template>
    <div class="vChat-middlebar flexbox flex__direction-column wrap-line">
        <div class="vc-searArea">
            <div class="iptbox flexbox">
                <el-input placeholder="搜索" prefix-icon="el-icon-search" v-model="data.inputValue" @change="changeInput" @keydown="textareaKeydow($event)"></el-input>
                <el-checkbox class="check" v-model="showCheck" label="仅未读" @change="changeCheck"></el-checkbox>
            </div>
            
        </div>
        
        <div class="vc-recordList flex1 flexbox flex__direction-column" style="overflow:auto;">
                <ul class="clearfix J__recordList">
                    <li class="flexbox flex-alignc wc__material-cell pointer" @click="selectClick(item)" v-for="(item,index) in data.list" :key="index"  :class="(item.FromSerialNo+item.RobotSerialNo||'')==data.seriNo?'selected':''">
                        <div class="info-wrap flex1 cap" v-if="item.groupFlag">
                            <img class="badge-img" src="@/assets/imgs/chat_icon_group.png" alt="">
                            客户群
                        </div>
                        <div class="info-wrap flex1 cap" v-else-if="item.userFlag">
                            <img class="badge-img" src="@/assets/imgs/chat_icon_single.png" alt="">
                            客户
                        </div>
                        <div class="info-wrap flex1" v-else>
                            <div class="img" style="width:50px;">
                                <el-icon :size="15" color="#F59A23" class="icon-size" v-if="item.IsCollect==1"><StarFilled /></el-icon>
                                <div class="icon-size" v-else></div>
                                <img class="avator" src="@/assets/imgs/default_user.png" v-if="item.FromHeadImg.length<=0&&item.Type==2"/>
                                <img class="avator" src="@/assets/imgs/group_default.jpg" v-if="item.FromHeadImg.length<=0&&item.Type==1"/>
                                <img class="avator" :src="item.FromHeadImg" v-if="item.FromHeadImg.length>0"/>
                            </div>
                            <div class="info flex1">
                                <el-popover placement="top-start" title="" trigger="hover" :content="item.FromNickName">
                                    <template #reference>
                                        <h2 class="title clamp1">{{item.FromNickName}}</h2>
                                        <p class="desc clamp1">{{item.subName}}</p>
                                    </template>
                                </el-popover>
                                
                                <em class="wc__badge" style="margin-left:5px;" v-if="data.robotObj.IsOnline==10&&item.NotifyCount>0">{{item.NotifyCount}}</em>
                                <em class="my_badge" style="margin-left:5px;" v-if="data.robotObj.IsOnline==10&&item.NotifyCount==0"></em> 
                            </div>
                        </div>
                    </li>
                </ul>
        </div>
       
    </div>
</template>

<script>
import { unref, reactive, ref, onMounted } from "vue";
import { SearchContact } from "@/helper/chat";
import { StarFilled } from "@element-plus/icons";

export default {
  name: "groupCop",
    components: {
        StarFilled
    },
  setup(props,context) {
    const showCheck=ref(false)
    
    const data = reactive({
        list:[],
        defalutList:[],
        inputValue:'',
        seriNo:'',
        robotNo:'',
        robotObj:{}
    })

    onMounted(()=>{
   
    })
    //重置数据
    function resetData(list,item){
        data.defalutList = list||[]
        data.robotNo = item.RobotSerialNo||''
        data.robotObj = item
        // data.seriNo = list[0]?list[0].FromSerialNo:''
        data.seriNo = list[0]?list[0].FromSerialNo+list[0].RobotSerialNo||'':''
          console.log("12321312",data.seriNo)
        changeInput()
    }

    //全局搜索
    function globalSearch(item,chatObj){
        data.robotNo = item.RobotSerialNo||''
        data.robotObj = item
        // data.seriNo = chatObj.FromSerialNo
        data.seriNo = chatObj.FromSerialNo+chatObj.RobotSerialNo||''
      
        data.inputValue =  chatObj.FromNickName
        let options = {
            Name:data.inputValue,
            RobotSerialNo:data.robotNo
        }
        SearchContact(options).then(res=>{
            data.list = filterData(res.List||[])
            for(let obj of data.list){
                if(obj.FromSerialNo ==  chatObj.FromSerialNo){
                    context.emit('onChoose',obj)
                    break
                }
            }
        })
        
    }

    //清空搜索条件
    function clearKey(){
        data.inputValue = ''
    }

    /****************socket事件*********************/
    function socketUpData(socketList){
        data.defalutList = socketList||[]
        changeInput()
        
    }
    /************************点击事件***************/
    //是否选中未读数
    function changeCheck(){
        changeInput()
    }
    //搜索
    function changeInput(e){
        if(data.inputValue.length>0){
            let options = {
                Name:data.inputValue,
                RobotSerialNo:data.robotNo
            }
            SearchContact(options).then(res=>{
                data.list = filterData(res.List||[])
            })
        } else {
            data.list = filterData(data.defalutList)
        }
    }

    function textareaKeydow(event){
        if(event.keyCode == 13){
            changeInput()
        }
    }

    function filterData(res){
        let arr=  []
        let groupFlag = false
        let userFlag = false
        if(showCheck.value){
            for(let item of res){
                if(item.NotifyCount!=-1){
                    if(item.Type==1&&!groupFlag){
                        arr.push({groupFlag:true})
                        groupFlag = true
                    }
                    if(item.Type==2&&!userFlag){
                        arr.push({userFlag:true})
                        userFlag = true
                    }
                    arr.push(item)
                }
            }
        } else {
            for(let item of res){
                if(item.Type==1&&!groupFlag){
                    arr.push({groupFlag:true})
                    groupFlag = true
                }
                if(item.Type==2&&!userFlag){
                    arr.push({userFlag:true})
                    userFlag = true
                }
                arr.push(item)
            }
        }
        return arr
    }

    //选择聊天
    function selectClick(item){
        // data.seriNo = item.FromSerialNo||''
         data.seriNo = item.FromSerialNo+item.RobotSerialNo||''
        context.emit('onChoose',item)
    }

    return {
        data,
        selectClick,
        resetData,
        changeInput,
        socketUpData,
        textareaKeydow,
        clearKey,
        changeCheck,
        showCheck,
        filterData,
        globalSearch
    };
  }
};
</script>

<style scoped>
.info-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #dedede;
    height: 50px;
    padding: 0 10px;
    box-sizing: border-box;
}

.info{
    height: 100%;
    display: flex;
    align-items: center;
}

.avator{
    width: 30px !important;
    height: 30px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.wrap-line{
    border-left: 1px solid #e4e7ed;
    border-right: 1px solid #e4e7ed;
}
.selected{
    background-color: #efefef;
}
.pointer{
    cursor: pointer;
}
.my_badge{
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: #ff3b30;
}

:deep().el-checkbox__label{
    padding-left: 5px !important;
}
:deep().el-checkbox{
    margin-left:10px;
    display: flex;
    align-items: center;
}
.badge-img{
    width:12px;
    height: 12px;
    margin-right: 5px;
}

.cap{
    height: 26px;
    background-color: #efefef;
}
.icon-size{
    height: 30px;
    line-height: 30px;
    width: 15px;
    margin-right: 5px;
}
</style>
