<template>
    <div class="side-wrap flexbox flex__direction-column">
        <div class="setting-header">
            <div class="search-wrap">
                <div class="cell" v-if="active==0">
                   <el-date-picker v-model="searchOption.date" type="daterange" placeholder="请选择时间"></el-date-picker>
                    <el-input v-model="searchOption.key" placeholder="请输入" style="width:300px;margin:0 10px"></el-input>
                    <el-select v-model="searchOption.state" placeholder="全部">
                        <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select> 
                </div>
                <div class="cell" v-if="active==1">
                   <el-input v-model="searchOption.groupName" placeholder="请输入群内用户昵称" style="width:300px;margin:0 10px"></el-input>
                    <div>
                        <el-button type='primary' @click="searchAction">搜索</el-button>
                        <el-button @click="resetAction">重置</el-button>
                    </div>
                </div>
            </div>
            <div v-if="active==0" class="search-wrap" style="justify-content: center;align-items: center;">
                <el-button type='primary' @click="searchAction">搜索</el-button>
                <el-button @click="resetAction">重置</el-button>
            </div>
        </div>
        <chatHistory v-if="active==0" ref="chatContentRef" @forceOut="forceOutAction" @recall='recallAction' @onIgnore="singleIgnoreAction" @responsecall="responsecallAction"></chatHistory>
        <chatGroupMember ref="chatMemberRef" v-if="active==1" />
        <div class="setting-footer">
            <div class="tab">
                <ul class="tab-list">
                    <li class="tab-li" :class="active == index ?'active' : '' " v-for="(item,index) in tabsList" @click="cutTabClick(index)" :key="index">{{item}}</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { unref, reactive, ref, onMounted } from "vue";
import chatHistory from './chatHistory'
import chatGroupMember from "./chatGroupMember"
import tools from '@/assets/js/tools'
export default {
    name:"sideCop",
    components:{
        chatHistory,chatGroupMember
    },
    setup(props,context){
        const searchOption = reactive({
            key:'',
            date:[tools.GetDay(-6,1),tools.GetDay(0,1)],
            state:0,
            groupName:""
        })
        const data = reactive({
            //当前聊天item
            chatItem:{},
            //当前机器人
            robotItem:{},
        })
        const chatContentRef = ref(null)
        const chatMemberRef=ref(null)
        const statusList = ref([{label:'全部',value:0},{label:'我发送的',value:1}])
        const tabsList=ref(['聊天记录'])
        const active=ref(0)
        /***************************参数更新*************************************/
        onMounted(()=>{ })
        
        function getData(data,searchOption) {     
            if(chatContentRef.value){
                chatContentRef.value.initOptions(data,searchOption)
            }
            if(chatMemberRef.value){
                chatMemberRef.value.getObj(data,searchOption)
            }
        }

        //展开后数据更新
        function initOptions(obj){
            searchOption.date = [tools.GetDay(-6,1),tools.GetDay(0,1)];
            searchOption.key= '';
            searchOption.state = 0;
            data.chatItem = obj.chatItem
            data.robotItem = obj.robotItem
            chatContentRef.value.initOptions(data,searchOption)
            // if(chatMemberRef.value){
            //     chatMemberRef.value.getObj(data,searchOption)
            // }
        }

        //全部忽略
        function ignoreAll(maxId){
            chatContentRef.value.ignoreAll(maxId)
        }
        //逐条忽略
        function ignoreSingleMsg(maxId){
            chatContentRef.value.ignoreSingleMsg(maxId)
        }
        
        //单条忽略
        function singleIgnoreAction(maxId){
            context.emit("onIgnore",maxId)
        }

        //@对方
        function responsecallAction(item){
            context.emit("responsecall",item)
        }

        /***************************点击事件*************************************/
        //回复
        function recallAction(item){
            context.emit('recall',item)
        }
        //强制踢人
        function forceOutAction(item){
             context.emit('forceOut',item)
        }
        //切换
        function cutTabClick(index){
            active.value=index
            getData(data,searchOption)
        }
        //搜索
        function searchAction(){
            if(active.value==0){
                chatContentRef.value.initQuery(searchOption)
            }
            if(active.value==1){
                chatMemberRef.value.searchObj(searchOption)
            }
            
        }
        //重置
        function resetAction(){
            if(active.value==0){
                searchOption.key='',
                searchOption.date=[tools.GetDay(-6,1),tools.GetDay(0,1)]
                searchOption.state=0
                chatContentRef.value.initQuery(searchOption)
            }
            if(active.value==1){
                searchOption.groupName='',
                chatMemberRef.value.searchObj(searchOption)
            }
            
        }
        return{
            searchOption,
            tabsList,
            statusList,
            active,
            resetAction,
            cutTabClick,
            searchAction,
            chatContentRef,
            recallAction,
            initOptions,
            data,
            ignoreAll,
            singleIgnoreAction,
            ignoreSingleMsg,
            responsecallAction,
            chatMemberRef,
            getData,
            forceOutAction
        }
    }
}
</script>

<style scoped>

.side-wrap{
    width: 490px;
    border-left: 1px solid #e4e7ed;
    box-sizing: border-box;
}

.setting-header{
    width: 100%;
    /* height: 100px; */
    padding-bottom:10px; 
    background-color: #fff;
    box-sizing: border-box;
    border-bottom: 1px solid #e4e7ed;
}

.search-wrap{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 20px 10px 0px;
    box-sizing: border-box;

}

.cell{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.setting-footer{
    width: 100%;
    height: 50px;
    background-color: #fff;
    display: flex;
    align-items: center;
    cursor: pointer;
    box-sizing: border-box;
    border-top: 1px solid #e4e7ed;
}
.tab{
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;

}
  .tab-list{
    width: 100%;
    display: flex;
    align-items: center;
  }
    .tab-li {
        width: 100px;
        height: 30px;
        border-radius: 0.27rem;
        text-align: center;
        line-height:30px;
        font-size: 14px;
        color: #606266;
        background: #ccc;
        border: 1px solid #ccc;
        margin-right: 5px;
        /* padding: 0 0.2rem; */
    }
  /*点击后样式*/
  .active{
    color: #000;
    background:#fff;
  }
</style>
