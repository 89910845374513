<!--群聊模板-->
<template>
  <div>
    <robotCop ref="robotRef" @onChoose="chooseRobot"></robotCop>
    <groupCop ref="groupRef" @onChoose="chooseClick" v-show="data.robotItem.IsOnline==10"></groupCop>
    <div class="vChat-container flex1 flexbox flex__direction-column" v-loading.fullscreen.lock="fullscreenLoading" element-loading-text="助手切换中……" v-show="data.robotItem.IsOnline==10">
      <div class="vChat__header">
        <div class="inner flexbox header">
          <img
            class="header-img"
            :src="data.chatItem.FromHeadImg"
            alt=""
            v-if="
              data.chatItem.FromHeadImg && data.chatItem.FromHeadImg.length > 0
            "
          />
          <h2 class="barTit flex1">{{ data.chatItem.FromNickName || "" }}<span v-if='data.chatItem.Type==1'>({{data.chatItem.FromUserCount}}人)</span></h2>
          
          <el-button v-if="data.chatItem.isRefresh&&data.chatItem.Type==1" type="primary" @click="refreshGroupCode(data.chatItem)" style="margin-top: 10px">刷新群码</el-button>
          <img v-else-if="!data.chatItem.isRefresh&&data.chatItem.Type==1" class="codeImg" :src="data.chatItem.FromQrCode" alt="" @click="imageClick(data.chatItem)">
          <el-button
            style="margin-top: 10px"
            @click="ignoreClick"
            ><img class="chat_see" src="@/assets/imgs/chat_see.png" alt=""><span class="theme-color">{{data.chatItem.Type==1?'已读该群':'已读该顾客'}}</span></el-button
          >

        </div>
      </div>
      <div
        class="fixGeminiscrollHeight"
        v-show="fixGeminiscrollHeight"
        style="font-size: 0; height: 1px"
      ></div>
      <chatContent
        ref="chatContentRef"
        @responsecall="responsecallAction"
        @recall="recallAction"
        @updateMaxId="updateMaxId"
        @onIgnore="singleIgnoreAction"
        @forceOut='forceOutAction'
      ></chatContent>

      <div class="vChat__footTool">
        <!-- 输入框模块 -->
        <div class="wc__editor-panel wc__borT flexbox flex__direction-column">
          <div class="wrap-toolbar">
            <div class="flexbox">
              <div class="flex1">
                <el-button @click="openPic"><img class="btn-img" src="@/assets/imgs/chat_btn_pic.png" alt="">图片</el-button>
                <el-button @click="openLink"><img class="btn-img" src="@/assets/imgs/chat_btn_link.png" alt="">链接</el-button>
                <el-button @click="openMini"><img class="btn-img" src="@/assets/imgs/chat_btn_mini.png" alt="">小程序</el-button>
                <el-button @click="openVideo" ><img class="btn-img" src="@/assets/imgs/chat_btn_video.png" alt="">视频</el-button>
                <el-button @click="openMemeber" ><img class="btn-img" src="@/assets/imgs/chat_btn_recall.png" alt="">成员</el-button>
                <el-button @click="openVideoNum" ><img class="btn-img" src="@/assets/imgs/video.png" alt="">视频号</el-button>
                <el-button @click="openInvite" v-if="data.chatItem.Type!=1"><img class="btn-img" src="@/assets/imgs/invite.png" alt="">入群邀请</el-button>
              </div>
              <div>
                <el-button @click="openSide">{{
                  showSide ? "收起" : "展开"
                }}</el-button>
              </div>
            </div>
          </div>
          <div class="send-wrap">
            <div class="wrap-editor flex1">
              <div class="reCall-wrap" v-if="data.reCallItem.FromNickName&&reCallType==1">
                <div class="recall-checkbox" v-if="data.chatItem.Type == 1">
                  <el-checkbox
                    v-model="tellTarget"
                    label="@对方"
                    @change="changeBox"
                  ></el-checkbox>
                </div>
                <div class="recall-content">
                  回复 {{ data.reCallItem.FromNickName }}:{{
                    data.reCallItem.MsgContent
                  }}
                </div>
                <el-icon :size="20" class="icon-class" @click="deleteReCall"
                  ><circle-close
                /></el-icon>
              </div>
  
              <div
                class="editor J__wcEditor delEditor"
                :style="
                  data.reCallItem.FromNickName&&reCallType==1
                    ? 'height:110px !important'
                    : 'height:150px !important'
                "
                contenteditable="true"
                ref="textRef"
                @input="changeText"
                innerHTML.length = "10"
                style="user-select: text; -webkit-user-select: text"
                v-html="data.reCallItem.FromNickName ? targetStr() : ''"
                @keydown="textareaKeydow($event)"
                @keyup="textareaKeyUp($event)"
                id="editText"
              ></div>
            </div>
            <div class="send-right">
                <button class="btn-submit J__wchatSubmit" @click="trunNext">
                换行
              </button>
              <button style="margin-top:10px;" :class="canClick?'':'noSend'" class="btn-submit J__wchatSubmit" :disabled="!canClick" @click="sendText">
                发送
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>  
    <sideCop
      ref="sideCop"
      v-show="showSide&&data.robotItem.IsOnline==10"
      @recall="recallAction"
      @onIgnore="singleIgnoreAction"
      @responsecall="responsecallAction"
      @forceOut='forceOutAction'
    ></sideCop>
    <el-empty description="助手已离线，请先登录助手" class="reset-robot" v-show="data.robotItem.IsOnline!=10">
      <el-button type="primary" @click="againLogin">去登录</el-button>
    </el-empty>
    <ImgChartSendCop
      ref="imgSendDialog"
      @onConfirm="addImgAction"
      @updateLocalSend="updateLocalMessage"
    />
    <LinkChartSendCop ref="linkSendDialog" @onConfirm="addLinkAction"/>
    <MiniChartSendCop ref="miniSendDialog" @onConfirm="addMiniAction" />
    <VideoChartSendCop
      ref="videoSendDialog"
      @onConfirm="addVideoAction"
      @updateLocalSend="updateLocalMessage"
     
    />
    <CreateRobAddress ref="creRobAddress" @onConfirm="confirmAddress" />
    <CreateCodeRob ref="createCodeRob" @onConfirm="confirmCodeRob"></CreateCodeRob>
    <MemberSelectCop ref="memberSelectRef" @onConfirm="confirmMember"/>
    <VideoNumChartSendCop ref="videoNumRef" @onConfirm="confirmNumAction"/>
    <QrcodeShowCop ref="qrcodeShowRef" />
    <InviteGroupCop ref="inviteGroupRef" @onConfirm="confirmInviteAction"/>
    
  </div>
</template>

<script>
import { reactive, ref, onMounted, computed, onUnmounted, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ElMessageBox, ElMessage } from "element-plus";
import groupCop from "./groupCop";
import sideCop from "./sideCop";
import robotCop from "./robotCop";
import chatContent from "./chatContent";
import QrcodeShowCop from '@/components/GroupManager/QrcodeShowCop'
import ImgChartSendCop from "@/components/ChatManager/ImgChartSendCop";
import LinkChartSendCop from "@/components/ChatManager/LinkChartSendCop";
import MiniChartSendCop from "@/components/ChatManager/MiniChartSendCop";
import VideoChartSendCop from "@/components/ChatManager/VideoChartSendCop";
import MemberSelectCop from "@/components/ChatManager/MemberSelectCop";
import VideoNumChartSendCop from "@/components/ChatManager/VideoNumChartSendCop"
import InviteGroupCop from "@/components/ChatManager/InviteGroupCop"
//群聊踢出弹框
// import OutMemberDialog from "@/components/ChatManager/OutMemberDialog"
import { ChatInit, SendMsg, IgnoreMsg } from "@/helper/chat";
import websocket from "@/assets/js/websocket";
import { CircleClose } from "@element-plus/icons";
import { useStore } from "vuex";
import CreateRobAddress from "@/components/RobotMag/CreateRobAddress";
import CreateCodeRob from "@/components/RobotMag/CreateCodeRob";
import { RemoveAllGroup,GetGroupQRcode} from "@/helper/group";
import _ from "lodash";
import Tool from "@/assets/js/tools";
export default {
  name: "Chat",
  components: {
    groupCop,
    sideCop,
    chatContent,
    robotCop,
    ImgChartSendCop,
    LinkChartSendCop,
    MiniChartSendCop,
    VideoChartSendCop,
    CircleClose,
    CreateCodeRob,
    CreateRobAddress,
    MemberSelectCop,
    VideoNumChartSendCop,
    QrcodeShowCop,
    InviteGroupCop
  },
  setup() {
    const store = useStore()
    const textSize=ref(0)
    const fixGeminiscrollHeight = ref(true);
    const imgSendDialog = ref(null);
    const linkSendDialog = ref(null);
    const miniSendDialog = ref(null);
    const videoSendDialog = ref(null);
    const showSide = ref(false);
    const textRef = ref(null);
    const groupRef = ref(null);
    const robotRef = ref(null);
    const chatContentRef = ref(null);
    const sideCop = ref(null);
    const canClick = ref(true)
    const fullscreenLoading = ref(false)
    const creRobAddress = ref(null)
    const createCodeRob = ref(null)
    const memberSelectRef = ref(null)
    const videoNumRef=ref(null)
    const inviteGroupRef = ref(null)

    //是否@对方
    const tellTarget = ref(true);
    //消息动作 0：普通 1:回复 2:@对方 3:@成员
    const reCallType = ref(0);
    //拼接@
    function targetStr() {
      let str = "";
      if (tellTarget.value && data.chatItem.Type == 1&&reCallType.value!=0) {
        str = `<span style='color:#BFB18A' contenteditable="false">@${data.reCallItem.FromNickName}&nbsp;</span><span style='color:#000000'></span>`;
      }
      return str;
    }

    let data = reactive({
      //回复目标msg
      reCallItem: {},
      //文本
      textarea: "",
      //当前聊天item
      chatItem: {},
      //当前机器人
      robotItem: {},
      //最近id,这条之前的消息要取消忽略
      msgID: "",
    });

    onMounted(() => {
      canClick.value = true
      setTimeout(() => {
        fixGeminiscrollHeight.value = false;
        addListion();
      }, 300);
    });

    onUnmounted(() => {
      websocket.sendCancelLoops();
    });
    initChat();
    function initChat(item = null) {
      let options = {
        RobotSerialNo: item ? item.RobotSerialNo : "",
        FromSerialNo: "",
        PageNum: 1,
        PageSize: 20,
      };
      //聊天初始化
      websocket.sendCancelLoops()
      fullscreenLoading.value = true
      ChatInit(options).then(res => {
        fullscreenLoading.value = false
        if (!item) {
          robotRef.value.resetData(res.RobotList);
          data.robotItem = res.RobotList[0] || {};
        }
        
        if(data.robotItem.RobotSerialNo){
          data.chatItem = res.ChatList[0] || {};
        } else {
          data.chatItem = res.CollectList[0] || {};
        }
        
        data.chatItem.endTime = Tool.GMTToStr(data.chatItem.FromQrCodeLastUpdate,2,7)
        data.chatItem.refreshTime = Tool.GMTToStr(data.chatItem.FromQrCodeLastUpdate,2,5)
        if(new Date().getTime()>new Date(data.chatItem.refreshTime).getTime()){
            data.chatItem.isRefresh= true
        }

        //发送socket消息
        if(data.robotItem.RobotSerialNo){
          options.RobotSerialNo = data.robotItem.RobotSerialNo;
        } else {
          options.RobotSerialNo = data.chatItem.RobotSerialNo;
        }
        
        options.FromSerialNo = data.chatItem.FromSerialNo;
        options.FirstMsgId =
          res.MessageList && res.MessageList.length > 0
            ? res.MessageList[0].MsgID
            : 0;
        websocket.sendInvoke(options);
        store.commit("setChatInfo", options);

        chatContentRef.value.resetData(
          res.MessageList,
          item ? item : data.robotItem,
          data.chatItem || {}
        );
        groupRef.value.resetData(data.robotItem.RobotSerialNo?res.ChatList:res.CollectList, item ? item : data.robotItem);
      });
    }

    //更改聊天
    function chooseClick(item) {
      data.chatItem = item;
      data.chatItem.endTime = Tool.GMTToStr(data.chatItem.FromQrCodeLastUpdate,2,7)
      data.chatItem.refreshTime = Tool.GMTToStr(data.chatItem.FromQrCodeLastUpdate,2,5)
      if(new Date().getTime()>new Date(data.chatItem.refreshTime).getTime()){
          data.chatItem.isRefresh= true
      }
      chatContentRef.value.updateChat(item);
      showSide.value = false;
      data.textarea = "";
      textRef.value.innerText = "";
      deleteReCall();
    }

    //更改助手
    function chooseRobot(item,chatObj) {
      data.robotItem = item;
      if(chatObj){
        groupRef.value.globalSearch(item,chatObj)
      } else {
        initChat(item);
        groupRef.value.clearKey()
      }
      
      chatContentRef.value.updateRobot(item);
      showSide.value = false;
      data.textarea = "";
      textRef.value.innerText = "";
      deleteReCall();
    }

    //更新最新消息id
    function updateMaxId(msgid) {
      data.msgID = msgid;
    }
    /***************************消息****************************/
    websocket.Created();
    //监听消息
    function addListion() {
      websocket.addListioner((res) => {
        console.log("消息推送", res);
        robotRef.value.socketUpData(res.RobotList);
        chatContentRef.value.socketUpData(res.MessageList);
        groupRef.value.socketUpData(data.robotItem.RobotSerialNo?res.ChatList:res.CollectList);
      });
    }

    //发送消息
    function sendMyMsg(msgType, msgid, content) {
      console.log(msgType);
      let options = {
        Type: data.chatItem.Type,
        ReplyMsgID: msgid,
        RobotSerialNo:data.robotItem.RobotSerialNo?data.robotItem.RobotSerialNo:data.chatItem.RobotSerialNo?data.chatItem.RobotSerialNo:'',
        FromSerialNo:data.chatItem.FromSerialNo,
      };
      if (msgType == "text") {
        options.TextContent = content;
        options.MaterialIDs = [];
        options.IsReply = reCallType.value==1?1:0
        options.AtGroupMemberSerialNos = reCallType.value==0?[]:[data.reCallItem.FromSerialNo]
        options.IsHit =
          data.reCallItem.FromNickName &&
          tellTarget.value &&
          data.chatItem.Type == 1
            ? 1
            : 0;
      } else {
        options.MaterialIDs = content;
        options.TextContent = [];
      }
      return SendMsg(options);
    }

    /*******************************点击事件*******************************************/
    //逐条忽略
    function singleIgnoreAction(id) {
      sideCop.value.ignoreSingleMsg(id);
      chatContentRef.value.ignoreSingleMsg(id);
    }
    //全部忽略
    const ignoreClick = _.debounce(
      () => {
        if (!canSend()) return;
        let options = {
          Type: data.chatItem.Type,
          RobotSerialNo: data.robotItem.RobotSerialNo?data.robotItem.RobotSerialNo:data.chatItem.RobotSerialNo?data.chatItem.RobotSerialNo:'',
          FromSerialNo: data.chatItem.FromSerialNo,
          MsgID: "",
        };
        IgnoreMsg(options).then((res) => {
          chatContentRef.value.ignoreAll();
          sideCop.value.ignoreAll();
          ElMessage({
            type: "success",
            message: "已处理",
          });
        });
      },
      1000,
      {
        leading: true, //在延迟开始前立即调用事件
        trailing: false, //在延时结束后不调用,保证事件只被触发一次
      }
    );
    //发送消息
    const sendText = _.debounce(
      () => {
        if (!canSend()) return;
        if (data.textarea.length <= 0) {
          ElMessage({
            type: "info",
            message: "请填写内容",
          });
          return;
        }else if(data.textarea.length>500){
          ElMessage({
            type: "error",
            message: "超过500字数",
          });
          return;
        }
        let msgid = data.msgID;
        let text = data.textarea;
        if (
          data.reCallItem.FromNickName &&
          text.indexOf(`@${data.reCallItem.FromNickName}`) > -1 &&
          tellTarget.value &&
          data.chatItem.Type == 1
        ) {
          text = text.substring(
            data.reCallItem.FromNickName.length + 2,
            text.length
          );
        }
        text = text.replace(/\s*$/, "");
        if (text.length <= 0) {
          ElMessage({
            type: "info",
            message: "请填写内容",
          });
          return;
        }

        if (reCallType.value==1) {
          msgid = data.reCallItem.MsgID;
          text = `'${data.reCallItem.FromNickName}:\n${data.reCallItem.MsgContent}'\n-------------\n${text}`;
        } else if(reCallType.value==2){
            msgid = data.reCallItem.MsgID;
        }

        canClick.value = false
        sendMyMsg("text", msgid, [text]).then((res) => {
          canClick.value = true
          updateLocalMessage(res.MessageList, msgid);
          data.reCallItem.Ack = 2;
          data.textarea = "";
          textSize.value = 0
          textRef.value.innerText = "";
          deleteReCall();
        }).catch(err=>{
          canClick.value = true
        });
      },
      1000,
      {
        leading: true, //在延迟开始前立即调用事件
        trailing: false, //在延时结束后不调用,保证事件只被触发一次
      }
    );

    //插入本地数据
    function updateLocalMessage(arr, msgid) {
      chatContentRef.value.setLocalMessage(arr, msgid);
      sideCop.value.ignoreAll(msgid);
    }

    //打开图片
    function openPic() {
      if (!canSend()) return;
      imgSendDialog.value.initCop(data);
    }
    //确认图片
    function addImgAction(item) {
      sendMyMsg("image", data.msgID, [item.ID]).then((res) => {
        updateLocalMessage(res.MessageList, data.msgID);
      });
    }
    //打开链接
    function openLink() {
      if (!canSend()) return;
      linkSendDialog.value.initCop();
    }
    //确认链接
    function addLinkAction(item) {
      sendMyMsg("link", data.msgID, [item.ID]).then((res) => {
        updateLocalMessage(res.MessageList, data.msgID);
      });
    }
    //打开小程序
    function openMini() {
      if (!canSend()) return;
      miniSendDialog.value.initCop();
    }
    function addMiniAction(item) {
      sendMyMsg("mini", data.msgID, [item.ID]).then((res) => {
        updateLocalMessage(res.MessageList, data.msgID);
      });
    }
    //打开视频
    function openVideo() {
      if (!canSend()) return;
      videoSendDialog.value.initCop(data);
    }
    //确认视频
    function addVideoAction(item) {
      sendMyMsg("video", data.msgID, [item.ID]).then((res) => {
        updateLocalMessage(res.MessageList, data.msgID);
      });
    }
    //打开成员
    function openMemeber() {
      if (!canSend()) return;
      memberSelectRef.value.initCop(data.chatItem.FromSerialNo);
    }
    //打开视频号
    function openVideoNum() {
      if (!canSend()) return;
      videoNumRef.value.initCop(data)
    }
    //确认视频号
    function confirmNumAction(item){
      sendMyMsg("videoNum", data.msgID, [item.ID]).then((res) => {
        updateLocalMessage(res.MessageList, data.msgID);
      });
    }
    //打开入群邀请
    function openInvite(){
      if (!canSend()) return;
      inviteGroupRef.value.initCop(data)
    }

    //确认邀请链接
    function confirmInviteAction(arr){
      updateLocalMessage(arr, data.msgID);
    }

    //打开侧边栏
    function openSide() {
      showSide.value = !showSide.value;
      if (showSide.value) {
        sideCop.value.initOptions(data);

      }
    }
    //是否能发送
    function canSend() {
      if (data.robotItem.IsOnline != 10) {
        ElMessage({
          type: "info",
          message: "助手已离线,无法进行操作",
        });
        return false;
      }
      return true;
    }

    //变更选中
    function changeBox() {
      if (tellTarget.value) {
        textRef.value.focus();
        setTimeout(() => {
          var range = window.getSelection(); //创建range
          range.selectAllChildren(textRef.value); //range 选择obj下所有子内容
          range.collapseToEnd(); //光标移至最后
        }, 0);
      } else {
        if (data.textarea.indexOf(`@${data.reCallItem.FromNickName}`) > -1) {
          data.textarea = data.textarea.substring(
            data.reCallItem.FromNickName.length + 1,
            data.textarea.length
          );
          textRef.value.innerText = data.textarea;
        //   textRef.value.innerText = '';
        } else {
          textRef.value.innerText = data.textarea;
        //  textRef.value.innerText = ""
        }
      }
    }

    //重新登陆
    function againLogin(){
      ElMessageBox.confirm(
        `请确认登录的助手名称：【${data.robotItem.NickName}】`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          creRobAddress.value.showDialog(data.robotItem.RobotSerialNo);
        }).catch(() => {});
    }

    //地理弹框确认
    function confirmAddress(SerialNo) {
      createCodeRob.value.showDialog(SerialNo);
    }

    //二维码弹框确认
    function confirmCodeRob(params) {
      chooseRobot(data.robotItem)
    }

    const qrcodeShowRef = ref(null)
    //刷新群码
    function refreshGroupCode(row){
      GetGroupQRcode({GroupID:'',GroupSerialNo:row.FromSerialNo}).then(res=>{
        ElMessage.success('刷新成功')
      })
    }

    //图片点击
    function imageClick(item){
      item.GroupName = item.FromNickName
      item.GroupQrCode = {
          QrCodeLastUpdateTime:item.FromQrCodeLastUpdate,
          QrCodeUrl:item.FromQrCode,
          QrCodeName:item.FromQrCodeName,
        }
      qrcodeShowRef.value.initCop(item)
    }
    /*******************工具栏**************************/
    //文本
    function changeText(e) {
        console.log("changeText==>",textRef.value.innerText,textRef.value.innerHTML)
      
    // //   if(textSize.value>500){
    //     textSize.value=500
    //     // e.target.blur();
    //     textRef.value.innerText=textRef.value.innerText.substr(0,500)
    //   }
      if (data.chatItem.Type == 1 && tellTarget.value && data.reCallItem.FromNickName) {
        if ( textRef.value.innerText.length <= data.reCallItem.FromNickName.length + 1 ) {
          data.textarea = "";
          textRef.value.innerText = "";
          tellTarget.value = false;

          reCallType.value = reCallType.value !=1?0:1
          return;
        } else {
          data.textarea = textRef.value.innerText;
        }
      } else {
        data.textarea = textRef.value.innerText;
      }

    }

    let key1 = 0
    let key2 = 0
    //抬键
    function textareaKeyUp(event){
        if(event.ctrlKey){
            key1 = 0
        }
        if(event.keyCode == 13){
            key2=0 
        }
    }

    //键盘按键监听
    function textareaKeydow(event) {
        if(event.ctrlKey){
            key1 = 1
        }
        if(event.keyCode == 13){
            key2 = 1 
        }

        if(key1 == 1&&key2 == 1){
            textareaRange();
        } else if(event.keyCode === 13){
            sendText();
            event.preventDefault(); // 阻止浏览器默认换行操作
            return false;
        }
    }

    //换行
    function trunNext(){
        textareaRange()
    }

    //换行处理
    function textareaRange() {
      var range = document.createRange();
      //返回用户当前的选区
      var sel = document.getSelection();
      //获取当前光标位置ß
      var offset = sel.focusOffset;
    if(data.chatItem.Type == 1 && tellTarget.value&&data.reCallItem.FromNickName){
        let spliceOffset = offset
        if(textRef.value.lastChild.innerHTML.length<=0){
            spliceOffset = offset+5
        }
        
        var content = textRef.value.lastChild.innerHTML;
        let returnJson = '\n'
        if(textRef.value.lastChild.innerHTML[spliceOffset]!='\n'&&textRef.value.lastChild.innerHTML.length == spliceOffset){
            returnJson = '\n\n'
        }
        
        textRef.value.lastChild.innerHTML =
        content.slice(0, spliceOffset) + returnJson + content.slice(spliceOffset); //设置光标为当前位置
        range.setStart(textRef.value.lastChild.childNodes[0], offset+1);
       
    } else {
        var content = textRef.value.innerHTML; //添加换行符\n
        let returnJson = '\n'
        //结尾需要两个空格，中间就一个
        if(textRef.value.innerHTML[offset]!='\n'&&textRef.value.innerText.length == offset){
            returnJson = '\n\n'
        }
        
        textRef.value.innerHTML =
        content.slice(0, offset) + returnJson + content.slice(offset); //设置光标为当前位置
        range.setStart(textRef.value.childNodes[0], offset + 1);
    }
        
      //使得选区(光标)开始与结束位置重叠
      range.collapse(true);
      //移除现有其他的选区
      sel.removeAllRanges();
      //加入光标的选区
      sel.addRange(range);
    }

    //删除回复
    function deleteReCall() {
      data.reCallItem = {};
      textSize.value = 0
      reCallType.value = 0
    }

    //回复行为
    function recallAction(item) {
        if(reCallType.value !=0&&textRef.value.lastChild){
            textRef.value.lastChild.innerText = ''
        }

        reCallType.value = 1
        tellTarget.value = true
        setTimeout(() => {
            var range = window.getSelection(); //创建range
            range.selectAllChildren(textRef.value); //range 选择obj下所有子内容
            range.collapseToEnd(); //光标移至最后
        }, 0);
        data.reCallItem = item;
    }

    //@对方行为
    function responsecallAction(item) {
        if(reCallType.value !=0&&textRef.value.lastChild){
            textRef.value.lastChild.innerText = ''
        }
        
        reCallType.value = 2
        tellTarget.value = true
        setTimeout(() => {
            var range = window.getSelection(); //创建range
            range.selectAllChildren(textRef.value); //range 选择obj下所有子内容
            range.collapseToEnd(); //光标移至最后
        }, 0);
        data.reCallItem = item;
    }

    //强制踢人
    function forceOutAction(item){
      data.reCallItem = item;
      let param={
        RobotSerialNo:data.robotItem.RobotSerialNo?data.robotItem.RobotSerialNo:data.reCallItem.RobotSerialNo?data.reCallItem.RobotSerialNo:'',
        SetBlackList:0,
        MemberSeiralNo:data.reCallItem.FromSerialNo
      }
      ElMessageBox.confirm(`确认将群成员【${data.reCallItem.FromNickName}】从所有群聊中踢出？`, '确认提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
      }).then(() => {
          RemoveAllGroup(param).then(res=>{
             ElMessage({
              type: 'success',
              message: '操作成功!',
            });
          })
      }).catch(() => {
            ElMessage({
              type: 'info',
              message: '已取消',
            });
      });
    }

    //确认成员
    function confirmMember(obj){
      if(reCallType.value !=0&&textRef.value.lastChild){
        textRef.value.lastChild.innerText = ''
      }
      reCallType.value = 3
      tellTarget.value = true
      setTimeout(() => {
            var range = window.getSelection(); //创建range
            range.selectAllChildren(textRef.value); //range 选择obj下所有子内容
            range.collapseToEnd(); //光标移至最后
      }, 0);
      let reCallObj = {
        FromNickName :obj.NickName,
        FromSerialNo:obj.MemberSerialNo
      }
      data.reCallItem = reCallObj
    }
    
    return {
      textSize,
      data,
      openPic,
      openMini,
      openVideo,
      addImgAction,
      addMiniAction,
      addLinkAction,
      openLink,
      miniSendDialog,
      videoSendDialog,
      imgSendDialog,
      linkSendDialog,
      addVideoAction,
      fixGeminiscrollHeight,
      chooseClick,
      openSide,
      showSide,
      deleteReCall,
      recallAction,
      changeText,
      textRef,
      initChat,
      groupRef,
      robotRef,
      chatContentRef,
      chooseRobot,
      sendText,
      ignoreClick,
      addListion,
      updateMaxId,
      sideCop,
      canSend,
      updateLocalMessage,
      tellTarget,
      targetStr,
      changeBox,
      singleIgnoreAction,
      textareaKeydow,
      textareaRange,
      fullscreenLoading,
      textareaKeyUp,
      trunNext,
      responsecallAction,
      reCallType,
      canClick,
      againLogin,
      creRobAddress,
      createCodeRob,
      confirmAddress,
      confirmCodeRob,
      memberSelectRef,
      openMemeber,
      openVideoNum,
      confirmMember,
      videoNumRef,
      confirmNumAction,
      forceOutAction,
      refreshGroupCode,
      imageClick,
      qrcodeShowRef,
      openInvite,
      inviteGroupRef,
      confirmInviteAction
    };
  },
};
</script>

<style lang="scss">
.header {
  justify-content: center;
  align-items: center;
}

.header-img {
  width: 40px;
  height: 40px;
  margin-left: 20px;
}

.send-wrap {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 10px 0;
  padding: 10px 15px 0;
  box-sizing: border-box;

  .send-right {
    width: 80px;
    // height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .reCall-wrap {
    width: 100%;
    height: 36px;
    padding: 0px 15px;
    display: flex;
    align-items: center;

    .recall-checkbox {
      margin-right: 10px;
    }

    .recall-content {
      padding: 5px 10px;
      color: #fff;
      background-color: #ccc;
      margin-right: 10px;
      border-radius: 30px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      height: 26px;
    }
  }
}

.icon-class {
  cursor: pointer;
  color: #bfb18a;
}

:deep().el-textarea__inner {
  border: none !important;
  border-radius: 0px !important;
  height: 110px !important;
}
:deep().el-textarea {
  border: none !important;
  border-radius: 0px !important;
  height: 110px !important;
}
.delEditor {
  -moz-user-modify: read-write-plaintext-only;
  -webkit-user-modify: read-write-plaintext-only;
  white-space:pre-line;
}

.btn-img{
    width: 14px;
    height: 14px;
    margin-right: 5px;
}
.el-button--mini{
    padding-left: 10px;
    padding-right: 10px;
}
.el-button--mini span{
    display: flex;
    align-items: center;
}
.noSend{
  background-color: #dcdcdc !important;
  cursor: not-allowed;
}

.reset-robot{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.codeImg{
  width: 30px;
  height: 30px;
  margin-right:10px;
  margin-top:10px;
  cursor:pointer;
}
.chat_see{
  width: 15px;
  height: 15px;
  margin-right: 5px;
}
</style>
