<template>
  <div>
    <el-dialog title="添加视频号" width="600px" v-model="dialogVisible">
     <div class="body-wrap">
      <el-input placeholder="请输入视频号编号/名称" prefix-icon="el-icon-search" v-model="searchObj.key" style="width:200px;" @change="serachVideoNum"></el-input>
            <div class="cell-wrap">
                <div :class="{'selectborder':selectItem.ID==item.ID,'noborder':selectItem.ID!=item.ID}" class="cell"  v-for="(item,index) in videoNums" :key="index" @click="selectAction(item)">
                    <div class="video-header">
                        <img class="videoimg" src="../../assets/imgs/video.png">
                        <div class="cell-desc">{{item.MsgDesc}}</div>
                    </div>
                    <img class="msgHref" :src="item.MsgHref">
                    <p class="cell-desc">【{{item.ID}}】{{item.Remark}}</p>

                    <div class="cell-tip" v-if="selectItem.ID==item.ID">
                        <el-icon :size="25" :color="'#BFB18A'">
                            <CircleCheckFilled />
                        </el-icon>
                    </div>
                </div>
            </div>
        </div>

      <template #footer>
        <div class="dialog-footer" >
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirmAction">确 定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, ref, onMounted } from "vue";
import { CircleCheckFilled } from "@element-plus/icons";
import { ElMessage } from "element-plus";
import { GetMaterialList } from "@/helper/material";
import _ from "lodash";
export default {
  components: {
    CircleCheckFilled,
  },
  setup(props, context) {
    const dialogVisible = ref(false);
    const selectItem = ref({})
    const searchObj = reactive({
      key: "",
    });
    const pageObj = reactive({
      pageIndex: 1,
      pageSize: 20,
      totalCount: 0,
    });

    function initCop() {
      dialogVisible.value = true;
      videoNums.value = [];
      searchObj.key = ''
      serachVideoNum();
    }

    //视频号
    const videoNums = ref([]);

    //搜索
    function serachVideoNum() {
      let param = {
        Type: 2017,
        PageNum: pageObj.pageIndex,
        PageSize: pageObj.pageSize,
        Content:searchObj.key
      };
      GetMaterialList(param).then((res) => {
        pageObj.totalCount = res.TotalCount;
        videoNums.value = res.List;
        res.List.forEach((item) => {
          item.isSelect = false;
        });
      });
    }

    /***************点击事件******************/
    //确定点击
     const confirmAction=_.debounce(()=>{
        if(selectItem.value.ID){
            dialogVisible.value = false;
            context.emit('onConfirm',selectItem.value);
        } else{
            ElMessage({
                type:"info",
                message:"请至少选一个视频号"
            })
        }
        
    },1000,{
      leading: true, //在延迟开始前立即调用事件
      trailing: false, //在延时结束后不调用,保证事件只被触发一次
    });

    //取消
    function cancelAction(){
        dialogVisible.value = false;
        context.emit('concel');
    }
    //选择
    function selectAction(item){
        selectItem.value = item
    }

    return {
      initCop,
      dialogVisible,
      confirmAction,
      searchObj,
      pageObj,
      videoNums,
      serachVideoNum,
      selectAction,
      cancelAction,
      selectItem
    };
  },
};
</script>
<style lang='scss' scoped>
.body-wrap {
  width: 100%;
  overflow: auto;

  .cell-wrap {
        width: 100%;
        max-height: 500px;
        margin: 10px auto;
        overflow: auto;
        display: grid;
        grid-template-columns: repeat(auto-fill, 160px);
        justify-content: space-between;
    .selectborder {
      border: 1px solid #C1AE83;
    }
     .noborder{
            border:1px solid #ccc;
        }

    .cell {
        position: relative;
        margin: 5px 0px;
        width:160px;
        height: 180px;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);
        box-sizing: border-box;
       .msgHref{
            width: 120px;
            height: 100px;
            padding:10px;
        }

      .cell-tip {
            position: absolute;
            top: 0;
            right: 0;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fff;
      }
      .cell-desc{
            width: 100%;
            font-size: 12px;
            padding: 0 5px;
            box-sizing: border-box;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            margin-top: 5px;
        }
      p {
        width: 100%;
        height: 20px;
        background-color: rgba($color: #000000, $alpha: 0.2);
        font-size: 12px;
        position: absolute;
        bottom: 0;
        left: 0;
        line-height: 20px;
        text-align: center;
        color: #fff;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
      }
    }
  }
}
.video-header{
    width: 100%;
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    // align-items: center;
    font-size: 14px;
    box-sizing: border-box;
    vertical-align: top;
     .videoimg{
            // width: 30px;
            height: 20px;
            padding: 5px;
            display: block;
        }
}
:deep().el-pager li.active{
    color: $color-common !important;
}
*, *:before, *:after {
    box-sizing: content-box;
}
.dialog-footer{
    box-sizing: border-box;
    :deep().el-button--mini{
        box-sizing: border-box;
    }
}
</style>